/* eslint-no-unused-vars: 0 */
import React, { useEffect, useState } from 'react'
import '../../scss/_createNewUser.scss'
import ModalSpiner from '../../components/util/ModalSpiner'
import loginServices from '../../services/login'
import ModalNewMessages from '../../components/util/ModalGeneral'
import $ from 'jquery'
import NewModalEmail from '../../components/util/ModalConfirmacionEmail'
const CreateNewUserForm = () => {
  const [typeInput, setTypeInput] = useState('password')
  $(document).ready(function () {
    $('#cellPhone').keypress(function (tecla) {
      if (tecla.charCode < 48 || tecla.charCode > 57) {
        return false
      }
    })
  })
  function viewPassword () {
    if ($('.pass').attr('type') === 'password' && pass1 !== '') {
      setTypeInput('text')
      $('#fa-pass').removeClass('fa-eye-slash')
      $('#fa-pass').addClass('fa-eye')
    } else if ($('.pass').attr('type') === 'text') {
      setTypeInput('password')
      $('#fa-pass').removeClass('fa-eye')
      $('#fa-pass').addClass('fa-eye-slash')
    }
  }
  function closeModal () {
    $('#alert').removeClass()
    $('#alert').addClass('no-success-panel')
    $('#alertPass').removeClass()
    $('#alertPass').addClass('no-success-panel')
  }

  const createUser = (event) => {
    if (event.keyCode === 13) {
      $('#btnCreateUser').click()
    }
  }
  // const [idEmpresa, setIdEmpresa] = useState(1)
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [movilPhone, setMovilPhone] = useState('')
  const [empresa, setIdEmpresa] = useState('')
  const [user, setUser] = useState('')
  const [pass1, setPass1] = useState('')
  const [spiner, setSpiner] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [header, setHeader] = useState('')
  const [coloHeader, setColorHeader] = useState('')
  const [modalButton, setModalButton] = useState('')
  const [body, setBody] = useState('')
  const [link, setLink] = useState('')
  const [handle, setHandle] = useState()
  const [hlink, setHlink] = useState()
  const [isOpen2, setIsOpen2] = useState(false)
  const [header2, setHeader2] = useState('')
  const [coloHeader2, setColorHeader2] = useState('')
  const [body2, setBody2] = useState('')
  const [hclose2, setHclose2] = useState('')
  const [close2, setClose2] = useState('')
  useEffect(() => {
    // setIdEmpresa(1)
    setName('')
    setLastName('')
    setEmail('')
    setMovilPhone('')
    setUser('')
    setLink('')
    setPass1('')
    setSpiner(false)
    $('#alert').removeClass()
    $('#alert').addClass('no-success-panel')
  }, [])
  const clickAlert = (op) => () => {
    if (op === 1) {
      $('#alert').removeClass()
      $('#alert').addClass('success-panel')
      $('#alertPass').removeClass()
      $('#alertPass').addClass('no-success-panel')
    }
    if (op === 2) {
      $('#alertPass').removeClass()
      $('#alertPass').addClass('success-panel')
      $('#alert').removeClass()
      $('#alert').addClass('no-success-panel')
    }
  }
  const handleClose = (op) => () => {
    setIsOpen(false)
    window.location.href = global.ROUTE_LOGIN_BASIC
  }
  const handleClose2 = (op) => () => {
    setIsOpen2(false)
    window.location.href = global.ROUTE_LOGIN_BASIC
  }
  const handleClose1 = (op) => () => {
    setIsOpen(false)
  }
  const sendValidateConfirmation = () => async () => {
    setSpiner(true)
    const data = await loginServices.sendMail({
      proceso: global.USER_ID
    })
    if (data.error === 0) {
      setSpiner(false)
      setIsOpen(false)
      setIsOpen2(true)
      setBody2('¡Email reenviado exitosamente!')
      setColorHeader2('text-end close-x p-0')
      setHeader2(' ')
      setClose2('X')
      setHclose2(handleClose2)
      $('.MuiPaper-rounded').addClass('text-center align-items-center')
    }
    setSpiner(false)
  }
  const createUserHandler = async (event) => {
    $(global.FIELD).html(' ')
    $(global.INPUT_FIELD).removeClass('error')
    try {
      setSpiner(true)
      const userData = await loginServices.CrearUsuario({
        empresa,
        name,
        lastName,
        email,
        movilPhone,
        user,
        pass1
      })
      console.log(userData)
      setSpiner(false)
      if (userData.error === 1) {
        $(global.FIELD).html(global.LABEL_INPUT_ERROR)
        $(global.INPUT_FIELD).addClass('error')
        if (global.FIELD === '.name-input') {
          $('#lastname-space').addClass('space')
        }
        if (global.FIELD === '.last-name-input') {
          $('#name-space').addClass('space')
          $('#name-space').removeClass()
          $('#lastname-space').removeClass()
        }
      } else if (userData.code === 200) {
        $('#name-space').removeClass()
        $('#lastname-space').removeClass()
        setIsOpen(true)
        setHeader(userData.header)
        setBody(userData.body)
        setColorHeader('notify-color text-center')
        setModalButton('Aceptar')
        setHandle(handleClose)
        $('.MuiPaper-rounded').addClass('text-center align-items-center')
      } else if (userData.error === 401 || userData.error === 500 || userData.error === 400) {
        $('#name-space').removeClass()
        $('#lastname-space').removeClass()
        setIsOpen(true)
        setHeader(userData.header)
        setBody(userData.body)
        setColorHeader('notify-color text-center')
        setModalButton('Aceptar')
        setHandle(handleClose1)
        $('.MuiPaper-rounded').addClass('text-center align-items-center')
      } else {
        $('#name-space').removeClass()
        $('#lastname-space').removeClass()
        setIsOpen(true)
        setHeader(userData.header)
        setBody(userData.body)
        setLink(userData.link)
        setHlink(sendValidateConfirmation)
        setColorHeader('notify-color text-center')
        setModalButton('Aceptar')
        setHandle(handleClose)
        $('.MuiPaper-rounded').addClass('text-center align-items-center')
      }
    } catch (e) {
      setSpiner(false)
      setIsOpen(true)
      setHeader(global.MODAL_HEAD_ERROR)
      setBody(global.ERROR_TRYCATCH)
      setTimeout(function () {
        setIsOpen(false)
      }, 3000)
      throw e
    }
  }
  return (
    <div>
      <ModalNewMessages isOpen={isOpen} header={header} body={body} color={coloHeader} button={modalButton} link={link} handle={handle} hlink={hlink}/>
      <ModalSpiner opt={spiner} />
      <NewModalEmail isOpen={isOpen2} header={header2} body={body2} color={coloHeader2} hclose={hclose2} close={close2}/>
      <form className='position-forms'>
        <div className='row col-md-11' id='forms-user'>
          <div className='col-md-12'><div className="form-group ml-5 mb-4"><h3><b>Regístrate</b></h3></div></div>
          <div className='col-md-6'>
            <div className="form-group ml-5">
              <div className="wrap-input100 validate-input m-b-26 every-input" id='name'>
                <label id='name-space' className="form-label-create-user"><b className='color-label'>Nombres: <b className='pink-color'>*</b> </b><span className="text-input name-input every-label"></span> </label>
                <input value={name} type="text" onKeyDown={(e) => createUser(e)} onChange={({ target }) => setName(target.value)} minLength="20" maxLength="100" className="item mt-2 color-label longitud" id="name" onClick={closeModal}/>
                <span className="focus-input100"></span>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="form-group ml-5">
              <div className="wrap-input100 validate-input m-b-26 every-input" id="lastName">
                <label id='lastname-space' className="form-label-create-user"><b className='color-label'>Apellidos: <b className='pink-color'>*</b></b> <span className="text-input last-name-input every-label longitud"></span> </label>
                <input value={lastName} type="text" onKeyDown={(e) => createUser(e)} onChange={({ target }) => setLastName(target.value)} minLength="20" maxLength="100" className="item mt-2 color-label" id="lastName" onClick={closeModal}/>
                <span className="focus-input100"></span>
              </div>
            </div>
          </div>
          <div className='col-md-12 mt-2'>
            <div className="form-group ml-5">
              <div className="wrap-input100 validate-input m-b-26 every-input" id="email">
                <label className="form-label-create-user"><b className='color-label'>Correo Electrónico: <b className='pink-color'>*</b></b> <span className="text-input mail-input every-label"></span> </label>
                <input value={email} type="text" onKeyDown={(e) => createUser(e)} onChange={({ target }) => setEmail(target.value)} minLength="20" maxLength="100" className="item longitud mt-2 color-label" id="email" onClick={closeModal}/>
                <span className="focus-input100"></span>
              </div>
            </div>
          </div>
          <div className='col-md-12 mt-2'>
            <div className="form-group ml-5">
              <div className="wrap-input100 validate-input m-b-26 every-input" id="cellPhone">
                <label className="form-label-create-user"><b className='color-label'>Teléfono móvil: <b className='pink-color'>*</b></b> <span className="text-input cell-phone-input every-label"></span></label>
                <input value={movilPhone} type='text' onKeyDown={(e) => createUser(e)} onChange={({ target }) => setMovilPhone(target.value)} minLength="20" maxLength="45" className="item mt-2 color-label" id="cellPhone" onClick={closeModal}/>
                <span className="focus-input100"></span>
              </div>
            </div>
          </div>
          <div className='col-md-12 mt-2'>
            <div className="form-group ml-5">
              <div className="wrap-input100 validate-input m-b-26 every-input" id="empresa">
                <label className="form-label-create-user"><b className='color-label'>Empresa: <b className='pink-color'>*</b></b> <span className="text-input cell-phone-input every-label"></span></label>
                <input value={empresa} type='text' onKeyDown={(e) => createUser(e)} onChange={({ target }) => setIdEmpresa(target.value)} minLength="20" maxLength="45" className="item mt-2 color-label" id="cellPhone" onClick={closeModal}/>
                <span className="focus-input100"></span>
              </div>
            </div>
          </div>
          <div className='col-md-12 mt-2'>
            <div className="form-group ml-5">
              <div className="wrap-input100 validate-input m-b-26 every-input" id="user">
                <label className="form-label-create-user"><b className='color-label'>Usuario: <b className='pink-color'>*</b></b><span className="text-input user-input every-label"></span> </label>
                <input value={user} type="text" onClick={clickAlert(1)} onKeyDown={(e) => createUser(e)} onChange={({ target }) => setUser(target.value)} minLength="20" maxLength="100" className="item mt-2 color-label" id="user" /><span className='no-success-panel' id='alert'><div className='position-alert'>{global.MESSAGES_ALERT}</div></span>
                <span className="focus-input100"></span>
              </div>
            </div>
          </div>
          <div className='col-md-12 mt-2'>
            <div className="form-group ml-5">
              <div className="wrap-input100 validate-input m-b-26 every-input" id="pass1">
                <label className="form-label-create-user"><b className='color-label'>Contraseña: <b className='pink-color'>*</b></b> <span className="text-input pass1-input every-label"></span> </label>
                <input value={pass1} type={typeInput} onClick={clickAlert(2)} onKeyDown={(e) => createUser(e)} onChange={({ target }) => setPass1(target.value)} minLength="8" maxLength="45" className="item mt-2 color-label pass" id="pass1" ></input>
                <span className="focus-input100"></span><span className='position-icon'><i className="far fa-eye-slash far-icon" onClick={viewPassword} id='fa-pass'></i></span><span className='no-success-panel' id='alertPass'><div className='position-alert_pass'>{global.MESSAGES_ALERT}</div></span>
              </div>
            </div>
          </div>
          <div className='col-md-12 mt-4'>
            <div className="form-group ml-5">
              <a onClick={createUserHandler} id='btnCreateUser' className="btn btn-block btn-primary create-button rounded-pill mb-2">Registrar</a>
            </div>
          </div>
        </div>
      </form>
    </div>

  )
}
// <div className='text-condicion'>Al registrarte, aceptas nuestras condiciones de uso y política de privacidad.</div><br/>
export default CreateNewUserForm
