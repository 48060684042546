/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable no-undef */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable space-infix-ops */
/* eslint-disable indent */
/* eslint-disable multiline-ternary */

import React, { useState, useEffect } from 'react'
import '../../scss/_templates.scss'
import '../../scss/_sticky_header.scss'
import img from '../../assets/img/temp4.PNG'
import templatesServices from '../../services/templates'
import ModalSpiner from '../../components/util/ModalSpiner'
import ModalDeleteTemplate from '../../components/util/ModalDeleteTemplate'
import ModalHtmlTemplate from '../../components/util/ModalHtmlTemplateHistory'
import Toast from '../../components/util/Toast'
import helpServices from '../../services/helper'
import loginServices from '../../services/login'
// import CarouselTemplateMail from './CarouselTemplateMail'
// import CarouselTemplateSms from './CarouselTemplateSms'
// import CarouselTemplateWhat from './CarouselTemplateWhat'
import $ from 'jquery'
import ReactTooltip from 'react-tooltip'

const TemplatesHistory = () => {
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [templates, setTemplastes] = useState([])
  const [spiner, setSpiner] = useState(false)
  const [contCab, setContCab] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [body, setBody] = useState('')
  const [coloHeader, setColorHeader] = useState('')
  const [modalButton, setModalButton] = useState('')
  const [modalButtonCerrar, setModalButtonCerrar] = useState('')
  const [handle, setHandle] = useState()
  const [isOpenV, setIsOpenV] = useState(false)
  const [coloHeaderV, setColorHeaderV] = useState('')
  const [headerV, setHeaderV] = useState('')
  const [handleV, setHandleV] = useState()
  const [modalButtonV, setModalButtonV] = useState('')
  const [bodyV, setModalBodyV] = useState('')
  const [typeModal, setTypeModal] = useState('')
  const [sizeModal, setSizeModal] = useState('')
  const [secondCont, setSecondCont] = useState(0)
  const [rowsCarousel, setRowsCarousel] = useState(0)
  const [search, setSearch] = useState('')
  const [cboOrder, setCboOrder] = useState(localStorage.getItem('order'))
  const [typeSearchModal, setTypeSearchModal] = useState('A')
  const [templatesPublic, setTemplastesPublic] = useState([])
  const [secondContMail, setSecondContMail] = useState(0)
  const [rowsCarouselMail, setRowsCarouselMail] = useState(0)
  const [contCabMail, setContCabMail] = useState()
  const [templatesPublicS, setTemplastesPublicS] = useState([])
  const [secondContSms, setSecondContSms] = useState(0)
  const [rowsCarouselSms, setRowsCarouselSms] = useState(0)
  const [contCabSms, setContCabSms] = useState()
  const [templatesPublicW, setTemplastesPublicW] = useState([])
  const [secondContWhat, setSecondContWhat] = useState(0)
  const [rowsCarouselWhat, setRowsCarouselWhat] = useState(0)
  const [contCabWhat, setContCabWhat] = useState()
  const [title, setTitle] = useState()
  let cSecondMail = 0
  let initContMail = 0
  let cSecondSms = 0
  let initContSms = 0
  let cSecondWhat = 0
  let initContWhat = 0
  let cSecond = 0
  // eslint-disable-next-line prefer-const
  let initCont = 0
  useEffect(() => {
    validarPermisos();
    try {
      setSpiner(true)
      localStorage.removeItem('Publica')
      if (!localStorage.getItem('menu')) {
        window.localStorage.setItem(
          'menu', JSON.stringify(1)
        )
      }
      if (cboOrder === null) {
        window.localStorage.setItem('order', 'fecha')
        setCboOrder('fecha')
      }
      if (localStorage.getItem('canalFilter')) {
        $('.A-select').removeClass('type-select')
        $('.T-select').removeClass('type-select')
        $('.H-select').removeClass('type-select')
        $('.E-select').removeClass('type-select')
        $('.' + localStorage.getItem('canalFilter') + '-select').addClass('type-select')
        setTypeSearchModal(localStorage.getItem('canalFilter'))
        if (localStorage.getItem('canalFilter') === 'A') {
          templastes()
        } else {
          templastesTipo(localStorage.getItem('canalFilter'))
        }
      } else {
        templastes()
      }
      templastesTipoM('H')
      templastesTipoS('T')
      templastesTipoW('E')
    } catch (e) {
      setSpiner(false)
    }
  }, [])

  const handleClose = (op) => () => {
    setIsOpen(false)
    setIsOpenV(false)
  }

  const templastes = async (event) => {
    setSpiner(true)
    const templates = await templatesServices.getplantillas(cboOrder)
    if (templates.error === 200) {
      $('.b').removeClass('active')
      $('.a').addClass('active')
      const data = templates.data.data.Fila
      setRowsCarousel(data.length)
      if (data.length === 0) {
        setSecondCont(0)
      } else {
        setSecondCont(1)
      }
      if (data.length < 4) {
        setContCab(1)
      } else {
        setContCab(Math.round((data.length) / 4) - 2)
      }
      setTemplastes(data)
      setSpiner(false)
      setSearch('')
      // mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE)
    } else {
      mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE_FALSE_ERROR_NOMBRE)
      setRowsCarousel(0)
      setSecondCont(0)
    }
  }

  const templastesCbo = async (event, opt) => {
    setSpiner(true)
    const templates = await templatesServices.getplantillas(event)
    if (templates.error === 200) {
      $('.b').removeClass('active')
      $('.a').addClass('active')
      const data = templates.data.data.Fila
      setRowsCarousel(data.length)
      if (data.length === 0) {
        setSecondCont(0)
      } else {
        setSecondCont(1)
      }
      if (data.length < 4) {
        setContCab(1)
      } else {
        setContCab(Math.round((data.length) / 4) - 2)
      }
      setTemplastes(data)
      setSpiner(false)
      // mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE)
    } else {
      mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE_FALSE_ERROR_NOMBRE)
      setRowsCarousel(0)
      setSecondCont(0)
    }
  }

  const templastesTipoM = async (event) => {
    const cboOrders = 'fecha'
    setSpiner(true)
    const templates = await templatesServices.getplantillasTipoPublic({ event })
    if (templates.error === 200) {
      $('.b').removeClass('active')
      $('.a').addClass('active')
      const data = templates.data.data.Fila
      setRowsCarouselMail(data.length)
      if (data.length === 0) {
        setSecondContMail(0)
      } else {
        setSecondContMail(1)
      }
      if (data.length < 4) {
        setContCabMail(1)
      } else {
        setContCabMail(Math.round((data.length) / 4) - 2)
      }
      setTemplastesPublic(data)
      setSpiner(false)
    } else {
      setRowsCarouselMail(0)
      setSecondContMail(0)
    }
  }

  const templastesTipoW = async (event) => {
    const cboOrders = 'fecha'
    setSpiner(true)
    const templates = await templatesServices.getplantillasTipoPublic({ event })
    if (templates.error === 200) {
      $('.b').removeClass('active')
      $('.a').addClass('active')
      const data = templates.data.data.Fila
      setRowsCarouselWhat(data.length)
      if (data.length === 0) {
        setSecondContWhat(0)
      } else {
        setSecondContWhat(1)
      }
      if (data.length < 4) {
        setContCabWhat(1)
      } else {
        setContCabWhat(Math.round((data.length) / 4) - 2)
      }
      setTemplastesPublicW(data)
      setSpiner(false)
    } else {
      setRowsCarouselWhat(0)
      setSecondContWhat(0)
    }
  }
  const templastesTipoS = async (event) => {
    const cboOrders = 'fecha'
    setSpiner(true)
    const templates = await templatesServices.getplantillasTipoPublic({ event })
    if (templates.error === 200) {
      $('.b').removeClass('active')
      $('.a').addClass('active')
      const data = templates.data.data.Fila
      setRowsCarouselSms(data.length)
      if (data.length === 0) {
        setSecondContSms(0)
      } else {
        setSecondContSms(1)
      }
      if (data.length < 4) {
        setContCabSms(1)
      } else {
        setContCabSms(Math.round((data.length) / 4) - 2)
      }
      setTemplastesPublicS(data)
      setSpiner(false)
    } else {
      setRowsCarouselSms(0)
      setSecondContSms(0)
    }
  }

  const templastesTipo = async (event) => {
    setSpiner(true)
    const templates = await templatesServices.getplantillasTipo({ event, cboOrder })
    if (templates.error === 200) {
      $('.b').removeClass('active')
      $('.a').addClass('active')
      const data = templates.data.data.Fila
      setRowsCarousel(data.length)
      if (data.length === 0) {
        setSecondCont(0)
      } else {
        setSecondCont(1)
      }
      if (data.length < 4) {
        setContCab(1)
      } else {
        setContCab(Math.round((data.length) / 4) - 2)
      }
      setTemplastes(data)
      setSpiner(false)
      setSearch('')
      // mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE)
    } else {
      mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE_FALSE_ERROR_NOMBRE)
      setRowsCarousel(0)
      setSecondCont(0)
    }
  }

  const templastesTipoCbo = async (event, cboOrder) => {
    setSpiner(true)
    const templates = await templatesServices.getplantillasTipo({ event, cboOrder })
    if (templates.error === 200) {
      $('.b').removeClass('active')
      $('.a').addClass('active')
      const data = templates.data.data.Fila
      setRowsCarousel(data.length)
      if (data.length === 0) {
        setSecondCont(0)
      } else {
        setSecondCont(1)
      }
      if (data.length < 4) {
        setContCab(1)
      } else {
        setContCab(Math.round((data.length) / 4) - 2)
      }
      setTemplastes(data)
      setSpiner(false)
      // mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE)
    } else {
      mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE_FALSE_ERROR_NOMBRE)
      setRowsCarousel(0)
      setSecondCont(0)
    }
  }

  $(document).ready(function () {
    const hide = '.' + typeSearchModal + '-select'
    window.localStorage.setItem('canalFilter', typeSearchModal)
    $('#btnTodos').unbind().click(function () {
      $(hide).removeClass('type-select')
      $('.A-select').addClass('type-select')
      // window.location.href = global.ROUTE_TEMPLASTES
      setTypeSearchModal('A')
      templastes()
    })

    $('#btnSms').unbind().click(function () {
      $(hide).removeClass('type-select')
      $('.T-select').addClass('type-select')
      // window.location.href = global.ROUTE_TEMPLASTES
      setTypeSearchModal('T')
      templastesTipo('T')
    })
    $('#btnMail').unbind().click(function () {
      $(hide).removeClass('type-select')
      $('.H-select').addClass('type-select')
      // window.location.href = global.ROUTE_TEMPLASTES
      setTypeSearchModal('H')
      templastesTipo('H')
    })
    $('#btnWhatsapp').unbind().click(function () {
      $(hide).removeClass('type-select')
      $('.E-select').addClass('type-select')
      // window.location.href = global.ROUTE_TEMPLASTES
      setTypeSearchModal('E')
      templastesTipo('E')
    })
  })

  const duplic = async (idTemplate, type) => {
    setSpiner(true)
    const templates = await templatesServices.duplyTemplate(idTemplate)
    setSpiner(false)
    mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
    if (templates.error === 200) {
      setTimeout(function () {
        window.location.href = global.ROUTE_TEMPLASTES
      }, 2000)
    }
  }

  const edit = (idTemplate, type) => {
    window.localStorage.setItem('newTemplate', 1)
    window.localStorage.setItem('editTipo', JSON.stringify(type))
    if (type === 'T') {
      window.location.href = global.ROUTE_EDITSMSTEMPLATES
      window.localStorage.setItem('idPlantillaSms', JSON.stringify(idTemplate))
    }
    if (type === 'H') {
      window.location.href = global.ROUTE_EDITMAILTEMPLATES
      window.localStorage.setItem('idPlantilla', JSON.stringify(idTemplate))
    }
    if (type === 'E') {
      window.location.href = global.ROUTE_EDITWHATTEMPLATES
      window.localStorage.setItem('idPlantillaWhat', JSON.stringify(idTemplate))
    }
  }

  const usar = (idTemplate, type) => {
    window.localStorage.setItem('newTemplate', 1)
    window.localStorage.setItem('editTipo', JSON.stringify(type))
    if (type === 'T') {
      window.location.href = global.ROUTE_CREATESMSTEMPLATES
      window.localStorage.setItem('idPlantillaSms', JSON.stringify(idTemplate))
      window.localStorage.setItem('usarPublica', 'P')
      window.localStorage.setItem('Publica', true)
    }
    if (type === 'H') {
      window.location.href = global.ROUTE_CREATEMAILTEMPLATES
      window.localStorage.setItem('idPlantilla', JSON.stringify(idTemplate))
      window.localStorage.setItem('usarPublica', 'P')
      window.localStorage.setItem('Publica', true)
    }
    if (type === 'E') {
      window.location.href = global.ROUTE_CREATEWHATTEMPLATES
      window.localStorage.setItem('idPlantillaWhat', JSON.stringify(idTemplate))
      window.localStorage.setItem('usarPublica', 'P')
      window.localStorage.setItem('Publica', true)
    }
  }

  const trash = async (idTemplate, type) => {
    setIsOpen(true)
    setColorHeader(global.MODAL_HEAD_DELETE_TEMPLATE)
    setBody(global.MODAL_DELETE_TEMPLATE)
    setModalButton(global.ACCEPT)
    setModalButtonCerrar(global.CANCEL)
    setHandle(handleClose)
    $('#idDeleteTemplate').val(idTemplate)
  }

  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }

  const exportHtml = async (idTemplate, type) => {
    setSpiner(true)
    setTitle(idTemplate)
    const templates = await templatesServices.loadDetail(idTemplate)
    if (templates.error === 200) {
      setTypeModal(type)
      setIsOpen(true)
      if (templates.data.data.row.length > 0) {
        if (type === 'H') {
          setSizeModal('lg')
          setModalBodyV(<div
            dangerouslySetInnerHTML={{
              __html: templates.data.data.row[0].contenido
            }}></div>)
        }
        if (type === 'T') {
          setSizeModal('sm')
          setModalBodyV(<div
            dangerouslySetInnerHTML={{
              __html: templates.data.data.row[0].contenido
            }}></div>)
        }
        if (type === 'E') {
          setSizeModal('sm')
          setModalBodyV(<div
            dangerouslySetInnerHTML={{
              __html: templates.data.data.row[0].contenido
            }}></div>)
        }
        setIsOpenV(true)
        setHeaderV('Preview')
        setColorHeaderV()
        setModalButtonV(global.CLOSE)
        setHandleV(handleClose)
      }
      setIsOpen(false)
    }
    setSpiner(false)
    mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
  }

  const clickSearch = async (event) => {
    if (event.keyCode === 13 & search !== '') {
      setContCab(0)
      setSpiner(true)
      const templates = await templatesServices.getplantillasNombre({ search, typeSearchModal, cboOrder })
      if (templates.error === 200) {
        $('.b').removeClass('active')
        $('.a').addClass('active')
        const dataList = templates.data.data.Fila
        setRowsCarousel(dataList.length)
        if (dataList.length > 0) {
          setSecondCont(1)
          if (typeSearchModal === 'A') {
            if (dataList.length < 4) {
              setContCab(1)
            } else {
              setContCab(Math.round((dataList.length) / 4) - 2)
            }
            setTemplastes(dataList)
          } else {
            $('.b').removeClass('active')
            $('.a').addClass('active')
            const dataList = templates.data.data.Fila
            if (dataList.length < 4) {
              setContCab(1)
            } else {
              setContCab(Math.round((dataList.length) / 4) - 2)
            }
            setTemplastes(dataList)
          }
          // mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE)
        } else {
          mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE_FALSE_ERROR_NOMBRE + ' ' + search)
          setSecondCont(0)
          setRowsCarousel(0)
        }
      } else {
        if (templates.error === 800) {
          mostrarMensaje(400, global.LABEL_INPUT_ERROR)
        } else {
          mostrarMensaje(global.CODEERROR, global.LOAD_TEMPLATE_FALSE_ERROR_NOMBRE + ' ' + search)
          setSecondCont(0)
          setRowsCarousel(0)
        }
      }
      setSpiner(false)
    } else {
      if (event.keyCode === 13 & search === '') {
        window.localStorage.setItem('canalFilter', typeSearchModal)
        window.location.href = global.ROUTE_TEMPLASTES
      }
    }
  }

  const clearRedirect = async (opt) => {
    window.localStorage.setItem('newTemplate', 1)
    if (opt === 'H') {
      window.localStorage.removeItem('idPlantilla')
      window.localStorage.removeItem('idDetailPlantilla')
      window.localStorage.removeItem('editTipo')
      window.location.href = global.ROUTE_CREATEMAILTEMPLATES
    } else if (opt === 'T') {
      window.localStorage.removeItem('idPlantillaSms')
      window.localStorage.removeItem('DetallePlantillaCreadaSms')
      window.localStorage.removeItem('editTipo')
      window.location.href = global.ROUTE_CREATESMSTEMPLATES
    } else {
      window.localStorage.removeItem('idPlantillaWhat')
      window.localStorage.removeItem('DetallePlantillaCreadaWath')
      window.localStorage.removeItem('editTipo')
      window.location.href = global.ROUTE_CREATEWHATTEMPLATES
    }
  }

  const handledOrder = async (opt) => {
    setCboOrder(opt)
    window.localStorage.setItem('order', opt)
    if (typeSearchModal === 'A') {
      templastesCbo(opt)
    } else {
      templastesTipoCbo(typeSearchModal, opt)
    }
  }
  const handleKeyPress = (event, valMax) => {
    let valid = helpServices.validaNombres(event)
    if (!valid) return event.preventDefault()
  }

  const favorito = async (idPlantilla) => {
    const favorito = await templatesServices.putFavoritos({ idPlantilla })
    if (favorito.data.code === 200) {
      if ($('#favorito' + idPlantilla).hasClass('far')) {
        mostrarMensaje('200', 'Plantilla agregada a favoritos')
        $('#favorito' + idPlantilla).removeClass('far fa-star notify-color')
        $('#favorito' + idPlantilla).addClass('fa fa-star yellow-color')
      } else if ($('#favorito' + idPlantilla).hasClass('fa')) {
        mostrarMensaje('200', 'Se quito plantilla de favoritos')
        $('#favorito' + idPlantilla).removeClass('fa fa-star yellow-color')
        $('#favorito' + idPlantilla).addClass('far fa-star notify-color')
      }
    } else {
      mostrarMensaje(400, global.LABEL_INPUT_ERROR)
    }
  }

  const download = async (idTemplate, nombre) => {
    setSpiner(true)
    const templates = await templatesServices.loadDetail(idTemplate)
    if (templates.error === 200) {
      if (templates.data.data.row.length > 0) {
        let datos = templates.data.data.row[0].contenido
        let textFileAsBlob = new Blob([datos], { type: 'text/plain' })
        let downloadLink = document.createElement('a')
        downloadLink.download = nombre + '.txt'
        downloadLink.innerHTML = 'Download File'
        if (window.webkitURL != null) {
          downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob)
        } else {
          downloadLink.href = window.URL.createObjectURL(textFileAsBlob)
          downloadLink.click() //= destroyClickedElement
          downloadLink.style.display = 'none'
          document.body.appendChild(downloadLink)
        }
        setSpiner(false)
        downloadLink.click()
      } else {
        setSpiner(false)
        mostrarMensaje('400', 'No tiene contenido para descargar')
      }
    }
  }

  const handleOnchange = event => {
    const vali2 = helpServices.verificaSoloTextoNumero(event.target.value)
    if (vali2 === false) {
      mostrarMensaje('400', 'No se permite caracteres especiales')
    } else {
      setSearch(event.target.value)
    }
  }

  //  Funcion para restringir los permisos 
  const [permisosPagina, setPermisosPagina] = useState([]);
  const [isValidCrearWhassap, setIsValidCrearWhassap] = useState(false);
  const [isValidCrearSms, setIsValidCrearSms] = useState(false);
  const [isValidCrearMail, setIsValidCrearMail] = useState(false);
  const [isValidConsultarPlantilla, setIsValidConsultarPlantilla] = useState(false);
  const validarPermisos = async () => {
    try {
      const response = await loginServices.ValidarToken();
      console.log(response.data.listPermisos);
      setPermisosPagina(response.data.listPermisos);
      const tienePermisoConsultarPlantilla = response.data.listPermisos.some(
        (permiso) => permiso.idPermiso === 16
      );
      if (!tienePermisoConsultarPlantilla) {
        window.location.href = global.ROUTE_HOME;
      }
      const tienePermisoCrearWhassapp = response.data.listPermisos.some(
        (permiso) => permiso.idPermiso === 14
      );
      setIsValidCrearWhassap(tienePermisoCrearWhassapp);
      const tienePermisoCrearSms = response.data.listPermisos.some(
        (permiso) => permiso.idPermiso === 15
      );
      setIsValidCrearSms(tienePermisoCrearSms);
      const tienePermisoCrearMail = response.data.listPermisos.some(
        (permiso) => permiso.idPermiso === 13
      );
      setIsValidCrearMail(tienePermisoCrearMail);
      if (!tienePermisoConsultarPlantilla) {
        window.location.href = global.ROUTE_DASHBOARD + '?param=true';
      }
      console.log(tienePermisoCrearMail, tienePermisoCrearSms, tienePermisoCrearWhassapp);
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
      <ModalSpiner opt={spiner} />
      <input id='idDeleteTemplate' type="hidden" />
      <ModalDeleteTemplate isOpen={isOpen} header={coloHeader} body={body} color={coloHeader} button={modalButton} buttonCerrar={modalButtonCerrar} handle={handle} />
      <ModalHtmlTemplate isOpen={isOpenV} title={title} size={sizeModal} type={typeModal} header={headerV} color={coloHeaderV} button={modalButtonV} handle={handleV} body={bodyV} />
      { /* <nav className='sub-header-menu'> */}
      <nav className='nav-sticky'>
        <ul className='ul-sticky'>
          <li className='li-sticky row'>
            <div style={{ paddingBottom: '15px', paddingLeft: '0px' }} className="row col-md-12">
              <div className='col-md-3 search-campaign'>
                <div className="row no-gutters align-items-center">
                  <div className="col col-md-12">
                    <input className="form-control border-secondary rounded-pill pr-5" id='search' onKeyPress={(event) => { handleKeyPress(event, 45) }} onChange={handleOnchange} value={search} onKeyDown={(e) => clickSearch(e)} placeholder={global.TEMPLATE_SEARCH} />
                  </div>
                  <div className="col-auto">
                    <a className="btn text-dark border-0 rounded-pill ml-n5 disabled-a">
                      <i className="fa fa-search disabled-a grey-color"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row form-inline-head float-left-btn  justify-content-between">
                {isValidCrearMail &&(
                  <a onClick={() => clearRedirect('H')} type="button" className="box-btn-template col-auto btn btn btn-success btn_template input-a"><i className='fa fa-envelope bullhorn'></i> {global.CREATE_TEMPLATE_MAIL} </a>
                )}
                                {' '}{' '}
                {isValidCrearSms &&(
                  <a onClick={() => clearRedirect('T')} type="button" className="box-btn-template col-auto btn btn btn-success btn_template input-a"><i className='fa fa-sms bullhorn'></i> {global.CREATE_TEMPLATE_SMS} </a>
                )}
                                {' '}{' '}
                {isValidCrearWhassap &&(
                  <a onClick={() => clearRedirect('E')} type="button" className="box-btn-template col-auto btn btn btn-success btn_template input-a"><i className='fab fa-whatsapp bullhorn'></i> {global.CREATE_TEMPLATE_WHATS} </a>
                )}
                
              </div>
            </div>
            <div className="col-md-12 panel-group panel_hist_template"></div>
            <div className="col-md-12 panel-group panel-title">
              <nav className="navbar navbar-expand-sm navbar-dark bg-black py-0 px-0">

                <button className="navbar-toggler mr-3" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className='col-md-8'>
                  <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav-second">
                      <li key={1} className="nav-item-second menu-1 active">
                        <label className="navbar-brand-second title-template" href="#">{global.TEMPLATE_TITLE}</label>
                        <span className="navbar-toggler-icon vertical"></span>
                      </li>
                      <div className='menu-padding'></div>
                      <li key={1} className="nav-item-second menu-2 active">
                        <a className="nav-link-second" href="#"> {global.TEMPLATE_FILTER} </a>
                      </li>
                      <li key={2} className="nav-item-second menu-2">
                        <a className="nav-link-second" id="btnTodos" href="#">{global.TEMPLATE_FILTER_ALL}<div className="A-select type-select" ></div></a>
                      </li>
                      <li key={3} className="nav-item-second menu-2">
                        <a className="nav-link-second" id="btnSms" href="#">{global.TEMPLATE_FILTER_SMS}<div className="T-select" ></div></a>
                      </li>
                      <li key={4} className="nav-item-second menu-2">
                        <a className="nav-link-second" id="btnMail" href="#">{global.TEMPLATE_FILTER_MAIL}<div className="H-select" ></div></a>
                      </li>
                      <li key={5} className="nav-item-second menu-2">
                        <a className="nav-link-second" id="btnWhatsapp" href="#">{global.TEMPLATE_FILTER_WHATSAPP}<div className="E-select" ></div></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav-second float-right-cbo pull-right">
                      <li key={1} className="nav-item-second menu-2 active order-by ">
                        <a className="nav-link-second" href="#"> {global.TEMPLATE_ORDER} </a>
                      </li>
                      <li key={2} className="nav-item-second order-by">
                        <select value={cboOrder} onChange={({ target }) => handledOrder(target.value)} className='form-control input-select input-select-size'>
                          <option value='fecha'>Mas recientes</option>
                          <option value='nombre'>Nombres</option>
                          <option value='favorito'>Favoritos</option>
                          <option value='usadas'>Mas usadas</option>
                        </select>
                        <div className="A-select type-select"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div className="col-md-12 panel-group panel_hist_template"></div>
          </li>
        </ul>
      </nav>
      <section className='section-sticky' id="first">
        <div className='col-md-12'>
          <div className="row">

            <div className="col-1 text-right">
              {
                rowsCarousel > 4 &&
                <a className="btn mb-3 center-h-v" href="#carouselExampleIndicators5" role="button" data-slide="prev">
                  <i className="fas fa-angle-left "></i>
                </a>
              }

            </div>
            {
              secondCont > 0
                ? <div className="col-10">
                  <div id="carouselExampleIndicators5" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active a" >
                        <div className="row">
                          {templates.map((val, key) => {
                            return (
                              <React.Fragment key={val.id}>
                                {
                                  key <= 3 &&
                                  <div className='col-md-3'>
                                    <div className="card pmd-card">
                                      <div className="card-header d-flex flex-row pmd-card-border">
                                        <a className="pmd-avatar-list-img" href="javascript:void(0);">
                                          <img className='templ' src={img} width="350" height="200" />
                                        </a>
                                      </div>
                                      <div className="card-body">
                                        {val.nombre.length > 25
                                          ? <div className='ajust-text ml-0' data-tip={`<p class="content-tool"> ${val.nombre.toLowerCase()}</p>`} data-html={true}>
                                    <label className='ml-2 mt-1'><b>{val.nombre.substring(0, 25) + '...'}</b></label>
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                          : val.nombre.length <= 0
                                            ? <div>
                                    sin datos...
                                  </div>
                                            : <>
                                  <label className='ml-2 mt-1'><b>{val.nombre.toLowerCase()}</b></label>
                                  </>
                              }
                                        <hr />
                                        <table cellPadding="1" className='table1'>
                                          <thead>
                                            <th className='notify-color'><label>{global.TYPE_TEMPLATE}</label></th>
                                            <th></th>
                                            <th className='notify-color'><label>{global.CREATE_DATE}</label></th>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              {val.tipo === 'H'
                                                ? <><td><label><i className="fa fa-envelope" aria-hidden="true"></i> MAIL</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>
                                                : val.tipo === 'T'
                                                  ? <><td><label><i className="fa fa-sms" aria-hidden="true"></i> SMS</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>
                                                  : <><td><label><i className="fab fa-whatsapp" aria-hidden="true"></i> WHATSAPP</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>

                                              }
                                            </tr>
                                          </tbody>
                                        </table>
                                        <hr />
                                        {isValidCrearMail || isValidCrearSms || isValidCrearWhassap ? (
                                          <div className="dropdown">
                                            <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                              <i className="fa fa-ellipsis-v fa-color ml-3"></i>
                                            </a>
                                            <div className="dropdown-menu dms" aria-labelledby="dropdownMenuButton">
                                              {val.tipo === 'H' ? <div onClick={() => download(val.idPlantilla, val.nombre)}><i className='ml-2 fa fa-download notify-color'></i><i className='ml-3 font-syle'>Descargar</i></div> : ''}
                                              <div onClick={() => favorito(val.idPlantilla)}> {val.esFavorito === 'S' ? <i className='ml-2 fa fa-star yellow-color' id={'favorito' + val.idPlantilla}></i> : val.esFavorito === 'N' ? <i className='ml-2 far fa-star notify-color' id={'favorito' + val.idPlantilla}></i> : <i className='ml-2 far fa-star notify-color' id={'favorito' + val.idPlantilla}></i>}<i className='ml-3 font-syle'>Favorito</i></div>
                                              <div onClick={() => exportHtml(val.idPlantilla, val.tipo)}><i className='ml-2 far fa-eye notify-color'></i><i className='ml-3 font-syle'>Ver</i></div>
                                              <div onClick={() => edit(val.idPlantilla, val.tipo)}><i className="ml-2 fa fa-edit notify-color fa-style"></i><i className='ml-3 font-syle'>Editar</i></div>
                                              <div onClick={() => duplic(val.idPlantilla, val.tipo)}><i className='ml-2 far fa-copy notify-color'></i><i className='ml-3 font-syle'>Duplicar</i></div>
                                              <div onClick={() => trash(val.idPlantilla, val.tipo)}><i className='ml-2 far fa-trash-alt notify-color'></i><i className='ml-3 font-syle'>Eliminar</i></div>
                                              <i></i>
                                            </div>
                                          </div>
                                        ) : null}
                                        
                                      </div>
                                    </div>
                                  </div>
                                }
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </div>
                      {
                        rowsCarousel > 4 &&
                        templates.map((val, key) => {
                          if (key <= contCab) {
                            initCont = initCont + 4
                            cSecond = initCont + 4
                          }
                          return (
                            <React.Fragment key={val.id}>
                              {
                                key <= contCab &&
                                <div className={'carousel-item b '} >
                                  <div className="row">
                                    {templates.map((val, key) => {
                                      return (
                                        <React.Fragment key={val.id}>
                                          {
                                            key <= initCont
                                              ? ''
                                              : key <= cSecond &&
                                              <div className='col-md-3'>
                                                <div className="card pmd-card">
                                                  <div className="card-header d-flex flex-row pmd-card-border">
                                                    <a className="pmd-avatar-list-img" href="javascript:void(0);">
                                                      <img className='templ' src={img} width="350" height="200" />
                                                    </a>
                                                  </div>
                                                  <div className="card-body">
                                                    {val.nombre.length > 15
                                                      ? <div className='ajust-text ml-0' data-tip={`<p class="content-tool"> ${val.nombre.toLowerCase()}</p>`} data-html={true}>
                                                          <label className='ml-2 mt-1'><b>{val.nombre.substring(0, 16) + '...'}</b></label>
                                                          <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                                        </div>
                                                        : val.nombre.length <= 0
                                                        ? <div>
                                                          sin datos...
                                                        </div>
                                                                              : <>
                                                        <label className='ml-2 mt-1'><b>{val.nombre.toLowerCase()}</b></label>
                                                        </>
                                                    }
                                                    <hr />
                                                    <table cellPadding="1" className='table1'>
                                                      <thead>
                                                        <th className='notify-color'><label>Tipo de Plantilla</label></th>
                                                        <th></th>
                                                        <th className='notify-color'><label>Fecha de Creación</label></th>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          {val.tipo === 'H'
                                                            ? <><td><label><i className="fa fa-envelope" aria-hidden="true"></i> MAIL</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>
                                                            : val.tipo === 'T'
                                                              ? <><td><label><i className="fa fa-sms" aria-hidden="true"></i> SMS</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>
                                                              : <><td><label><i className="fab fa-whatsapp" aria-hidden="true"></i> WHATSAPP</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>

                                                          }
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                    <hr />
                                                    {isValidCrearMail || isValidCrearSms || isValidCrearWhassap ? (
                                                        <div className="dropdown">
                                                        
                                                        <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                          <i className="fa fa-ellipsis-v fa-color ml-3"></i>
                                                        </a>
                                                        <div className="dropdown-menu dms" aria-labelledby="dropdownMenuButton">
                                                        {val.tipo === 'H' ? <div onClick={() => download(val.idPlantilla, val.nombre)}><i className='ml-2 fa fa-download notify-color'></i><i className='ml-3 font-syle'>Descargar</i></div> : ''}
                                                          <div onClick={() => favorito(val.idPlantilla)}> {val.esFavorito === 'S' ? <i className='ml-2 fa fa-star yellow-color' id={'favorito' + val.idPlantilla}></i> : val.esFavorito === 'N' ? <i className='ml-2 far fa-star notify-color' id={'favorito' + val.idPlantilla}></i> : <i className='ml-2 far fa-star notify-color' id={'favorito' + val.idPlantilla}></i>}<i className='ml-3 font-syle'>Favorito</i></div>
                                                          <div onClick={() => exportHtml(val.idPlantilla, val.tipo)}><i className='ml-2 far fa-eye notify-color'></i><i className='ml-3 font-syle'>Ver</i></div>
                                                          <div onClick={() => edit(val.idPlantilla, val.tipo)}><i className="ml-2 fa fa-edit notify-color fa-style"></i><i className='ml-3 font-syle'>Editar</i></div>
                                                          <div onClick={() => duplic(val.idPlantilla, val.tipo)}><i className='ml-2 far fa-copy notify-color'></i><i className='ml-3 font-syle'>Duplicar</i></div>
                                                          <div onClick={() => trash(val.idPlantilla, val.tipo)}><i className='ml-2 far fa-trash-alt notify-color'></i><i className='ml-3 font-syle'>Eliminar</i></div>
                                                          <i></i>
                                                        </div>
                                                      </div>

                                                    ) : null}
                                                    
                                                  </div>
                                                </div>
                                              </div>
                                          }
                                        </React.Fragment>
                                      )
                                    })}
                                  </div>
                                </div>
                              }
                            </React.Fragment>
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
                : <div className="col panel panel-default text-center">
                  <div className="panel-body">
                    <h3 style={{ fontSize: '14px' }}>{global.TEMPLATE_NOT_INFO}</h3>
                  </div>
                </div>
            }

            <div className="col-1 text-right">
              {
                rowsCarousel > 4 &&
                <a className="btn mb-3 center-h-v" href="#carouselExampleIndicators5" role="button" data-slide="next">
                  <i className="fas fa-angle-right"></i>
                </a>
              }

            </div>
          </div>
        </div>
      </section>
      <section>
      <div className='col-md-12'>
        <h5 className='mt-4 ml-5'>Plantillas predeterminadas mail</h5>
        <hr/>
          <div className="row mt-5">

            <div className="col-1 text-right">
              {
                rowsCarouselMail > 4 &&
                <a className="btn mb-3 center-h-v" href="#carouselExampleIndicators6" role="button" data-slide="prev">
                  <i className="fas fa-angle-left "></i>
                </a>
              }

            </div>
            {
              secondContMail > 0
                ? <div className="col-10">
                  <div id="carouselExampleIndicators6" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active a" >
                        <div className="row">
                          {templatesPublic.map((val, key) => {
                            return (
                              <React.Fragment key={val.id}>
                                {
                                  key <= 3 &&
                                  <div className='col-md-3'>
                                    <div className="card pmd-card">
                                      <div className="card-header d-flex flex-row pmd-card-border">
                                        <a className="pmd-avatar-list-img" href="javascript:void(0);">
                                          <img className='templ' src={img} width="350" height="200" />
                                        </a>
                                      </div>
                                      <div className="card-body">
                                        {val.nombre.length > 25
                                          ? <div className='ajust-text ml-0' data-tip={`<p class="content-tool"> ${val.nombre.toLowerCase()}</p>`} data-html={true}>
                                    <label className='ml-2 mt-1'><b>{val.nombre.substring(0, 25) + '...'}</b></label>
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                          : val.nombre.length <= 0
                                            ? <div>
                                    sin datos...
                                  </div>
                                            : <>
                                  <label className='ml-2 mt-1'><b>{val.nombre.toLowerCase()}</b></label>
                                  </>
                              }
                                        <hr />
                                        <table cellPadding="1" className='table1'>
                                          <thead>
                                            <th className='notify-color'><label>{global.TYPE_TEMPLATE}</label></th>
                                            <th></th>
                                            <th className='notify-color'><label>{global.CREATE_DATE}</label></th>
                                          </thead>
                                          <tbody>
                                            <tr><><td><label><i className="fa fa-envelope" aria-hidden="true"></i> MAIL</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <hr />
                                        <div className="dropdown">
                                          <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-ellipsis-v fa-color ml-3"></i>
                                          </a>
                                          <div className="dropdown-menu dms2" aria-labelledby="dropdownMenuButton">
                                            <div onClick={() => usar(val.idPlantilla, val.tipo)}><i className="ml-2 fa fa-edit notify-color fa-style"></i><i className='ml-3 font-syle'>Usar</i></div>
                                            <i></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </div>

                      {
                        rowsCarouselMail > 4 &&
                        templatesPublic.map((val, key) => {
                          if (key <= contCabMail) {
                            initContMail = initContMail + 4
                            cSecondMail = initContMail + 4
                          }
                          return (
                            <React.Fragment key={val.id}>
                              {
                                key <= contCabMail &&
                                <div className={'carousel-item b '} >
                                  <div className="row">
                                    {templatesPublic.map((val, key) => {
                                      return (
                                        <React.Fragment key={val.id}>
                                          {
                                            key <= initContMail
                                              ? ''
                                              : key <= cSecondMail &&
                                              <div className='col-md-3'>
                                                <div className="card pmd-card">
                                                  <div className="card-header d-flex flex-row pmd-card-border">
                                                    <a className="pmd-avatar-list-img" href="javascript:void(0);">
                                                      <img className='templ' src={img} width="350" height="200" />
                                                    </a>
                                                  </div>
                                                  <div className="card-body">
                                                    {val.nombre.length > 15
                                                      ? <div className='ajust-text ml-0' data-tip={`<p class="content-tool"> ${val.nombre.toLowerCase()}</p>`} data-html={true}>
                                    <label className='ml-2 mt-1'><b>{val.nombre.substring(0, 16) + '...'}</b></label>
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                                      : val.nombre.length <= 0
                                                        ? <div>
                                    sin datos...
                                  </div>
                                                        : <>
                                  <label className='ml-2 mt-1'><b>{val.nombre.toLowerCase()}</b></label>
                                  </>
                              }
                                                    <hr />
                                                    <table cellPadding="1" className='table1'>
                                                      <thead>
                                                        <th className='notify-color'><label>Tipo de Plantilla</label></th>
                                                        <th></th>
                                                        <th className='notify-color'><label>Fecha de Creación</label></th>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <><td><label><i className="fa fa-envelope" aria-hidden="true"></i> MAIL</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                    <hr />
                                                    <div className="dropdown">
                                                      <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fa fa-ellipsis-v fa-color ml-3"></i>
                                                      </a>
                                                      <div className="dropdown-menu dms2" aria-labelledby="dropdownMenuButton">
                                                      <div onClick={() => usar(val.idPlantilla, val.tipo)}><i className="ml-2 fa fa-edit notify-color fa-style"></i><i className='ml-3 font-syle'>Usar</i></div>
                                                      <i></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          }
                                        </React.Fragment>
                                      )
                                    })}
                                  </div>
                                </div>
                              }
                            </React.Fragment>
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
                : <div className="col panel panel-default text-center">
                  <div className="panel-body">
                    <h3 style={{ fontSize: '14px' }}>{global.TEMPLATE_NOT_INFO}</h3>
                  </div>
                </div>
            }

            <div className="col-1 text-right">
              {
                rowsCarouselMail > 4 &&
                <a className="btn mb-3 center-h-v" href="#carouselExampleIndicators6" role="button" data-slide="next">
                  <i className="fas fa-angle-right"></i>
                </a>
              }

            </div>
          </div>
        </div>
      </section>
      <section>
      <div className='col-md-12'>
        <h5 className='mt-4 ml-5'>Plantillas predeterminadas sms</h5>
        <hr/>
          <div className="row mt-5">

            <div className="col-1 text-right">
              {
                rowsCarouselSms > 4 &&
                <a className="btn mb-3 center-h-v" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <i className="fas fa-angle-left "></i>
                </a>
              }

            </div>
            {
              secondContSms > 0
                ? <div className="col-10">
                  <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active a" >
                        <div className="row">
                          {templatesPublicS.map((val, key) => {
                            return (
                              <React.Fragment key={val.id}>
                                {
                                  key <= 3 &&
                                  <div className='col-md-3'>
                                    <div className="card pmd-card">
                                      <div className="card-header d-flex flex-row pmd-card-border">
                                        <a className="pmd-avatar-list-img" href="javascript:void(0);">
                                          <img className='templ' src={img} width="350" height="200" />
                                        </a>
                                      </div>
                                      <div className="card-body">
                                        {val.nombre.length > 25
                                          ? <div className='ajust-text ml-0' data-tip={`<p class="content-tool"> ${val.nombre.toLowerCase()}</p>`} data-html={true}>
                                    <label className='ml-2 mt-1'><b>{val.nombre.substring(0, 25) + '...'}</b></label>
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                          : val.nombre.length <= 0
                                            ? <div>
                                    sin datos...
                                  </div>
                                            : <>
                                  <label className='ml-2 mt-1'><b>{val.nombre.toLowerCase()}</b></label>
                                  </>
                              }
                                        <hr />
                                        <table cellPadding="1" className='table1'>
                                          <thead>
                                            <th className='notify-color'><label>{global.TYPE_TEMPLATE}</label></th>
                                            <th></th>
                                            <th className='notify-color'><label>{global.CREATE_DATE}</label></th>
                                          </thead>
                                          <tbody>
                                            <tr><><td><label><i className="fa fa-sms" aria-hidden="true"></i> SMS</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <hr />
                                        <div className="dropdown">
                                          <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-ellipsis-v fa-color ml-3"></i>
                                          </a>
                                          <div className="dropdown-menu dms2" aria-labelledby="dropdownMenuButton">
                                            <div onClick={() => usar(val.idPlantilla, val.tipo)}><i className="ml-2 fa fa-edit notify-color fa-style"></i><i className='ml-3 font-syle'>Usar</i></div>
                                            <i></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </div>

                      {
                        rowsCarouselSms > 4 &&
                        templatesPublicS.map((val, key) => {
                          if (key <= contCabSms) {
                            initContSms = initContSms + 4
                            cSecondSms = initContSms + 4
                          }
                          return (
                            <React.Fragment key={val.id}>
                              {
                                key <= contCabSms &&
                                <div className={'carousel-item b '} >
                                  <div className="row">
                                    {templatesPublicS.map((val, key) => {
                                      return (
                                        <React.Fragment key={val.id}>
                                          {
                                            key <= initContSms
                                              ? ''
                                              : key <= cSecondSms &&
                                              <div className='col-md-3'>
                                                <div className="card pmd-card">
                                                  <div className="card-header d-flex flex-row pmd-card-border">
                                                    <a className="pmd-avatar-list-img" href="javascript:void(0);">
                                                      <img className='templ' src={img} width="350" height="200" />
                                                    </a>
                                                  </div>
                                                  <div className="card-body">
                                                    {val.nombre.length > 15
                                                      ? <div className='ajust-text ml-0' data-tip={`<p class="content-tool"> ${val.nombre.toLowerCase()}</p>`} data-html={true}>
                                    <label className='ml-2 mt-1'><b>{val.nombre.substring(0, 16) + '...'}</b></label>
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                                      : val.nombre.length <= 0
                                                        ? <div>
                                    sin datos...
                                  </div>
                                                        : <>
                                  <label className='ml-2 mt-1'><b>{val.nombre.toLowerCase()}</b></label>
                                  </>
                              }
                                                    <hr />
                                                    <table cellPadding="1" className='table1'>
                                                      <thead>
                                                        <th className='notify-color'><label>Tipo de Plantilla</label></th>
                                                        <th></th>
                                                        <th className='notify-color'><label>Fecha de Creación</label></th>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                        <><td><label><i className="fa fa-sms" aria-hidden="true"></i> SMS</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                    <hr />
                                                    <div className="dropdown">
                                                      <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fa fa-ellipsis-v fa-color ml-3"></i>
                                                      </a>
                                                      <div className="dropdown-menu dms2" aria-labelledby="dropdownMenuButton">
                                                      <div onClick={() => usar(val.idPlantilla, val.tipo)}><i className="ml-2 fa fa-edit notify-color fa-style"></i><i className='ml-3 font-syle'>Usar</i></div>
                                                      <i></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          }
                                        </React.Fragment>
                                      )
                                    })}
                                  </div>
                                </div>
                              }
                            </React.Fragment>
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
                : <div className="col panel panel-default text-center">
                  <div className="panel-body">
                    <h3 style={{ fontSize: '14px' }}>{global.TEMPLATE_NOT_INFO}</h3>
                  </div>
                </div>
            }

            <div className="col-1 text-right">
              {
                rowsCarouselSms > 4 &&
                <a className="btn mb-3 center-h-v" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <i className="fas fa-angle-right"></i>
                </a>
              }

            </div>
          </div>
        </div>
      </section>
      <section>
      <div className='col-md-12'>
        <h5 className='mt-4 ml-5'>Plantillas predeterminadas whatsapp</h5>
        <hr/>
          <div className="row mt-5">

            <div className="col-1 text-right">
              {
                rowsCarouselWhat > 4 &&
                <a className="btn mb-3 center-h-v" href="#carouselExampleIndicators4" role="button" data-slide="prev">
                  <i className="fas fa-angle-left "></i>
                </a>
              }

            </div>
            {
              secondContWhat > 0
                ? <div className="col-10">
                  <div id="carouselExampleIndicators4" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active a" >
                        <div className="row">
                          {templatesPublicW.map((val, key) => {
                            return (
                              <React.Fragment key={val.id}>
                                {
                                  key <= 3 &&
                                  <div className='col-md-3'>
                                    <div className="card pmd-card">
                                      <div className="card-header d-flex flex-row pmd-card-border">
                                        <a className="pmd-avatar-list-img" href="javascript:void(0);">
                                          <img className='templ' src={img} width="350" height="200" />
                                        </a>
                                      </div>
                                      <div className="card-body">
                                        {val.nombre.length > 25
                                          ? <div className='ajust-text ml-0' data-tip={`<p class="content-tool"> ${val.nombre.toLowerCase()}</p>`} data-html={true}>
                                    <label className='ml-2 mt-1'><b>{val.nombre.substring(0, 25) + '...'}</b></label>
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                          : val.nombre.length <= 0
                                            ? <div>
                                    sin datos...
                                  </div>
                                            : <>
                                  <label className='ml-2 mt-1'><b>{val.nombre.toLowerCase()}</b></label>
                                  </>
                              }
                                        <hr />
                                        <table cellPadding="1" className='table1'>
                                          <thead>
                                            <th className='notify-color'><label>{global.TYPE_TEMPLATE}</label></th>
                                            <th></th>
                                            <th className='notify-color'><label>{global.CREATE_DATE}</label></th>
                                          </thead>
                                          <tbody>
                                            <tr><><td><label><i className="fab fa-whatsapp" aria-hidden="true"></i> WHATSAPP</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <hr />
                                        <div className="dropdown">
                                          <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-ellipsis-v fa-color ml-3"></i>
                                          </a>
                                          <div className="dropdown-menu dms2" aria-labelledby="dropdownMenuButton">
                                            <div onClick={() => usar(val.idPlantilla, val.tipo)}><i className="ml-2 fa fa-edit notify-color fa-style"></i><i className='ml-3 font-syle'>Usar</i></div>
                                            <i></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </div>

                      {
                        rowsCarouselWhat > 4 &&
                        templatesPublicW.map((val, key) => {
                          if (key <= contCab) {
                            initContWhat = initContWhat + 4
                            cSecondWhat = initContWhat + 4
                          }
                          return (
                            <React.Fragment key={val.id}>
                              {
                                key <= contCabWhat &&
                                <div className={'carousel-item b '} >
                                  <div className="row">
                                    {templatesPublicW.map((val, key) => {
                                      return (
                                        <React.Fragment key={val.id}>
                                          {
                                            key <= initContWhat
                                              ? ''
                                              : key <= cSecondWhat &&
                                              <div className='col-md-3'>
                                                <div className="card pmd-card">
                                                  <div className="card-header d-flex flex-row pmd-card-border">
                                                    <a className="pmd-avatar-list-img" href="javascript:void(0);">
                                                      <img className='templ' src={img} width="350" height="200" />
                                                    </a>
                                                  </div>
                                                  <div className="card-body">
                                                    {val.nombre.length > 15
                                                      ? <div className='ajust-text ml-0' data-tip={`<p class="content-tool"> ${val.nombre.toLowerCase()}</p>`} data-html={true}>
                                    <label className='ml-2 mt-1'><b>{val.nombre.substring(0, 16) + '...'}</b></label>
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                                      : val.nombre.length <= 0
                                                        ? <div>
                                    sin datos...
                                  </div>
                                                        : <>
                                  <label className='ml-2 mt-1'><b>{val.nombre.toLowerCase()}</b></label>
                                  </>
                              }
                                                    <hr />
                                                    <table cellPadding="1" className='table1'>
                                                      <thead>
                                                        <th className='notify-color'><label>Tipo de Plantilla</label></th>
                                                        <th></th>
                                                        <th className='notify-color'><label>Fecha de Creación</label></th>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                        <><td><label><i className="fab fa-whatsapp" aria-hidden="true"></i> WHATSAPP</label></td><td></td><td><label>{val.fechaCreacion}</label></td></>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                    <hr />
                                                    <div className="dropdown">
                                                      <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fa fa-ellipsis-v fa-color ml-3"></i>
                                                      </a>
                                                      <div className="dropdown-menu dms2" aria-labelledby="dropdownMenuButton">
                                                      <div onClick={() => usar(val.idPlantilla, val.tipo)}><i className="ml-2 fa fa-edit notify-color fa-style"></i><i className='ml-3 font-syle'>Usar</i></div>
                                                      <i></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          }
                                        </React.Fragment>
                                      )
                                    })}
                                  </div>
                                </div>
                              }
                            </React.Fragment>
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
                : <div className="col panel panel-default text-center">
                  <div className="panel-body">
                    <h3 style={{ fontSize: '14px' }}>{global.TEMPLATE_NOT_INFO}</h3>
                  </div>
                </div>
            }

            <div className="col-1 text-right">
              {
                rowsCarouselWhat > 4 &&
                <a className="btn mb-3 center-h-v" href="#carouselExampleIndicators4" role="button" data-slide="next">
                  <i className="fas fa-angle-right"></i>
                </a>
              }

            </div>
          </div>
        </div>
      </section>
      <br /><br />
      <br /><br />
    </>
  )
}

export default TemplatesHistory
