/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable no-undef */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable space-infix-ops */
/* eslint-disable padded-blocks */
/* eslint-disable indent */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './components/login/Login'
import Password from './components/login/ForgotPassword'
import User from './components/login/NewUser'
import Welcome from './components/welcome/Welcome'
import RestorePassword from './components/login/RestorePassword'
import Validate from './components/login/GrantedUser'
import Master from './components/master-page/AppMaster'
import globalServices from './services/global'
import './global.js'
import ActivacionEmail from './components/login/ActivacionEmail'
import Desuscripcion from './components/login/Desuscripcion'
import CongratulationRestore from './components/login/CongratulationRestore'
import ButtonCampaign from '../src/components/util/ButtonCampaign'
import loginServices from './services/login'
function App () {
  const [user, setUser] = useState(localStorage.getItem('loggedUser'))
  const [load, setLoad] = useState(false)
  useEffect(() => {
    const loggedUserJson = localStorage.getItem('loggedUser')
    if (loggedUserJson) {
      const user = JSON.parse(loggedUserJson)
      setUser(user)
    }
    verificaToken()
    validarPermisos()
  }, [])

  const verificaToken = async () => {
    await globalServices.verificaToken()
    setTimeout(() => {
      verificaToken()
    }, 600000)
    setLoad(true)
  }
  
  // setTimeout(function () {
  //   refreshTokenVerifyInactivity() 
  // }, 1000 * 60 * 60)

  // const refreshTokenVerifyInactivity = async (event) => {
  //   const resp = await globalServices.refreshTokenInactivity({})
  //   if (resp.expire_date) {
  //     await globalServices.logOut({})
  //   }
  // }
  //  Funcion para restringir los permisos 
  const [permisosPagina, setPermisosPagina] = useState([]);
  const [isValidProgramarCampana, setIsValidProgramarCampana] = useState(false);


  const validarPermisos = async () => {
    try {
      const response = await loginServices.ValidarToken();
      setPermisosPagina(response.data.listPermisos);
      
      const tienePermisoProgramarCampana = response.data.listPermisos.some(
        (permiso) => permiso.nombre === "PROGRAM_CAMPAIN" 
      );
      setIsValidProgramarCampana(tienePermisoProgramarCampana);
     
     
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      {
        user === null
          ? <Router>
            <Routes>
              <Route path={ global.ROUTE_LOGIN } caseSensitive={false} element={<Login />} />
              <Route path={ global.ROUTE_PASSWORD } caseSensitive={false} element={<Password />} />
              <Route path={ global.ROUTE_CHANGEPASSWORD } caseSensitive={false} element={<RestorePassword />} />
              <Route path={ global.ROUTE_CREATEUSER } caseSensitive={false} element={<User />} />
              <Route path={ global.ROUTE_VALIDATE } caseSensitive={false} element={<Validate />} />
              <Route path={ global.ROUTE_CONGRATULATIONPASSWORD} caseSensitive={false} element={<CongratulationRestore/>}/>
              <Route path={ global.ROUTE_ACTIVACIOMAIL} caseSensitive={false} element={<ActivacionEmail/>}/>
              <Route path={ global.ROUTE_DESUSCRIPT} caseSensitive={false} element={<Desuscripcion/>}/>
            </Routes>
          </Router>
          : load &&
          <Router>
            <Routes>
              <Route path={ global.ROUTE_WELCOME } name="Home" element={<Welcome />} />
              <Route path={ global.ROUTE_HOME } name="Home" element={<Master />} />
            </Routes>
          </Router>
      }
      {isValidProgramarCampana && <ButtonCampaign />}
      
    </>
  )
}

export default App
