/* eslint-disable react/jsx-no-undef */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react'
// import CampaignReport from './reports/CampaingnReport'
import '../scss/_report.scss'
import Toast from '../components/util/Toast'
import CampaignReport from './reports/CampaingnReport'
import ProgramacionReport from './reports/ProgramacionReport'
import ListaNegra from './reports/ListaNegra'
import ListaGris from './reports/ListaGris'
import GeneralReport from './reports/GeneralReport'
import Unificado from './reports/Unificado'
import ReporteHistorico from './reports/ReporteHistorico'
import loginServices from '../services/login'
// import $ from 'jquery'
const Report = () => {
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [opcion, setOpcion] = useState()
  
  const handleSegment = (op) => {
    setOpcion(op)
  }
  useEffect(() => {
    validarPermisos();
    console.log(validarPermisos());
    defaulthandleSegment()
  }, [])

  const defaulthandleSegment = () => {
    setOpcion()
  }

  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000);
  }

  const switchOpcion = () => {
    switch (opcion) {
      case 1:
        return <GeneralReport/>;
      case 2:
        return <CampaignReport />;
      case 3:
        return <ProgramacionReport />;    
      case 4:
        return <ListaNegra />;    
      case 5:
        return <ListaGris />; 
      case 6:
        return <ReporteHistorico />;
      case 7:
        return <Unificado />;
      default:
        return ''; 
    }
  }

   //  Funcion para restringir los permisos 
   const [permisosPagina, setPermisosPagina] = useState([]);
    const [isValidReporteCampanaEncolada, setIsValidReporteCampanaEncolada] = useState(false);
    const [isValidReporteGeneral, setIsValidReporteGeneral] = useState(false);
    const [isValidReporteProgramacion, setIsValidReporteProgramacion] = useState(false);
    const [isValidReporteListaNegra, setIsValidReporteListaNegra] = useState(false);
    const [isValidReporteListaGris, setIsValidReporteListaGris] = useState(false);
    const [isValidReporteHistorico, setIsValidReporteHistorico] = useState(false);
    const [isValidReporteUnificado, setIsValidReporteUnificado] = useState(false);
   const validarPermisos = async () => {
     try {
       const response = await loginServices.ValidarToken();
       console.log(response.data.listPermisos);
       setPermisosPagina(response.data.listPermisos);

       const tienePermisoReporteCampanaEncolada = response.data.listPermisos.some(
          (permiso) => permiso.nombre === "REPORT_WAIT_CAMPAINS"
        );
      setIsValidReporteCampanaEncolada(tienePermisoReporteCampanaEncolada);

      const tienePermisoReporteGeneral = response.data.listPermisos.some(
        (permiso) => permiso.nombre === "REPORT_GENERAL"
      );
      setIsValidReporteGeneral(tienePermisoReporteGeneral);

      const tienePermisoReporteProgramacion = response.data.listPermisos.some(
        (permiso) => permiso.nombre === "REPORT_FOR_PROGRAMATION"
      );
      setIsValidReporteProgramacion(tienePermisoReporteProgramacion);

      const tienePermisoReporteListaNegra = response.data.listPermisos.some(
        (permiso) => permiso.nombre === "BLACK_LIST"
      );
      setIsValidReporteListaNegra(tienePermisoReporteListaNegra);

      const tienePermisoReporteListaGris = response.data.listPermisos.some(
        (permiso) => permiso.nombre === "GREY_LIST"
      );
      setIsValidReporteListaGris(tienePermisoReporteListaGris);

      const tienePermisoReporteHistorico = response.data.listPermisos.some(
        (permiso) => permiso.nombre === 'REPORT_HISTORICAL'
      );
      setIsValidReporteHistorico(tienePermisoReporteHistorico);

      const tienePermisoReporteUnificado = response.data.listPermisos.some(
        (permiso) => permiso.nombre === 'REPORT_UNIFIED'
      );
      setIsValidReporteUnificado(tienePermisoReporteUnificado);

      if (tienePermisoReporteCampanaEncolada) {
        setOpcion(2)
      } else if (tienePermisoReporteGeneral) {
        setOpcion(1)
      } else if (tienePermisoReporteProgramacion) {
        setOpcion(3)
      } else if (tienePermisoReporteListaNegra) {
        setOpcion(4)
      } else if (tienePermisoReporteListaGris) {
        setOpcion(5)
      } else if (tienePermisoReporteHistorico) {
        setOpcion(6)
      } else if (tienePermisoReporteUnificado) {
        setOpcion(7)
      } else {
        window.location.href = global.ROUTE_DASHBOARD + '?param=true';
      }
     } catch (error) {
       console.error(error);
     }
   };

  return (
    <div className='container-fluid'>
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast}/>}
      <div className='align-items-center pb-3 pt-2 content-header p-0'>
        <div className='col-2 title-report mr-4 p-0'>
          <span>Generar Reporte</span>
        </div>
        {isValidReporteCampanaEncolada && (
          <div className='col-2 text-center p-0 menu-tabs'>
            <div className='menu-reporte' onClick={() => handleSegment(2)}>
              <i className='fa fa-area-chart'></i>
              <span>
                Campaña encolada
                <hr className= {`sub-color sub2 mt-2 ${opcion === 2 ? 'success-panel' : 'd-none'}` }></hr>
              </span>           
            </div>
          </div>
        )}
        {isValidReporteGeneral && (
          <div className='col-2 text-center p-0 menu-tabs'>
            <div className='menu-reporte' onClick={() => handleSegment(1)}>
              <i className='fa fa-bar-chart'></i>
              <span>
                Reporte General 
                <hr className= {`sub-color sub2 mt-2 ${opcion === 1 ? 'success-panel' : 'd-none'}` }></hr>
              </span>   
            </div>
          </div>
        )}
        {isValidReporteProgramacion && (
          <div className='col-3 text-center p-0 menu-tabs tabs-prog'>
            <div className='menu-reporte' onClick={() => handleSegment(3)}>
              <i className='far fa-clock'></i>
              <span>
                Reporte por programación
                <hr className= {`sub-color sub2 mt-2 ${opcion === 3 ? 'success-panel' : 'd-none'}` }></hr>     
              </span> 
            </div>
          </div>
        )}
        {isValidReporteListaNegra && (
          <div className='col-3 text-center p-0 menu-tabs'>
            <div className='menu-reporte' onClick={() => handleSegment(4)}>
              <i className='fa fa-user-times'></i>
              <span>
                Lista Negra
                <hr className= {`sub-color sub2 mt-2 ${opcion === 4 ? 'success-panel' : 'd-none'}` }></hr>     
              </span> 
            </div>
          </div>
        )}
        {isValidReporteListaGris && (
          <div className='col-3 text-center p-0 menu-tabs'>
            <div className='menu-reporte' onClick={() => handleSegment(5)}>
              <i className='fa fa-user-times'></i>
              <span>
                Lista Gris
                <hr className= {`sub-color sub2 mt-2 ${opcion === 5 ? 'success-panel' : 'd-none'}` }></hr>     
              </span> 
            </div>
          </div>
        )}
        {isValidReporteHistorico && (
        <div className='col-3 text-center p-0 menu-tabs'>
          <div className='menu-reporte' onClick={() => handleSegment(6)}>
            <i className='fas fa-file-signature estiloRH'></i>
            <span>
              Reporte Historico
              <hr className= {`sub-color sub2 mt-2 ${opcion === 6 ? 'success-panel' : 'd-none'}` }></hr>
            </span>
          </div>
        </div>
        )}
        {isValidReporteUnificado && (
          <div className='col-3 text-center p-0 menu-tabs'>
            <div className='menu-reporte' onClick={() => handleSegment(7)}>
              <i className='fas fa-file-alt estilo'></i>
              <span>
                Reporte Unificado
                <hr className= {`sub-color sub2 mt-2 ${opcion === 7 ? 'success-panel' : 'd-none'}` }></hr>
              </span>
            </div>
          </div>
        )}
      </div>
      { switchOpcion() }
    </div>
  )
}

export default Report
