/* eslint-disable indent */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react'
import '../../../scss/_templates.scss'
import ModalHtmlTemplate from '../../../components/util/ModalHtmlTemplate'
import ModalConfiguracionTemplates from '../../../components/util/ModalConfiguracionTemplates'
import ModalSpiner from '../../../components/util/ModalSpiner'
import ModalNewMessages from '../../../components/util/ModalGeneral'
import ModalHtmlBasic from '../../../components/util/ModalHtmlRepo'
import templateServices from '../../../services/templates'
import ModalCancelTemplate from '../../../components/util/ModalCancelTemplate'
import Toast from '../../../components/util/Toast'
import { template } from './template'
import helpServices from '../../../services/helper'
import { Unlayer2be } from 'unlayer2be'
import servi from '../../../services/login'
import $, { event } from 'jquery'
const EditTemplate = () => {
  const [nameTemplate, setNameTemplate] = useState('')
  const [descTemplate, setDescTemplate] = useState('')
  const [nameRem, setNameRem] = useState(global.NOMBRE_REMITENTE)
  const [descRem, setDescRem] = useState(global.DIRECCION_REMITENTE)
  const [asunto, setAsunto] = useState('')
  const [spiner, setSpiner] = useState(false)
  const [isOpenM, setIsOpenM] = useState(false)
  const [headerM, setHeaderM] = useState('')
  const [handle4, setHandle4] = useState()
  const [bodyM, setBodyM] = useState('')
  const [modalButtonM, setModalButtonM] = useState('')
  const [coloHeaderM, setColorHeaderM] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [coloHeader, setColorHeader] = useState('')
  const [header, setHeader] = useState('')
  const [handle, setHandle] = useState()
  const [modalButton, setModalButton] = useState('')
  const [body, setModalBody] = useState('')
  const emailEditorRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState()
  const [selectedFileAdd, setSelectedFileAdd] = useState()
  const [isOpenHtml, setIsOpenHtml] = useState(false)
  const [headerHtml, setHeaderHtml] = useState('')
  const [handle2, setHandle2] = useState()
  const [bodyHtml, setBodyHtml] = useState('')
  const [modalButtonHtml, setModalButtonHtml] = useState('')
  const [coloHeaderHtml, setColorHeaderHtml] = useState('')
  const [imgData, setImgData] = useState([])
  const [fileData, setFileData] = useState([])
  const [showLoad, setShowLoad] = useState(true)
  const [isOpenC, setIsOpenC] = useState(false)
  const [headerC, setHeaderC] = useState('')
  const [handleC, setHandleC] = useState()
  const [bodyC, setBodyC] = useState('')
  const [modalButtonC, setModalButtonC] = useState('')
  const [coloHeaderC, setColorHeaderC] = useState('')
  const [modalButtonCerrarC, setModalButtonCerrarC] = useState('')
  const [type, setType] = useState('')
  const [showAddFiles, setShowAddFiles] = useState(false)
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [opcion, setOpcion] = useState(1)
  const [newHtml, setNewHtml] = useState()
  const [valor, setValor] = useState(false)

  // unlayer.loadDesign(template)

  useEffect(() => {
    setSpiner(true)
    const idPlantillaSms = localStorage.getItem('idPlantilla')
    if (idPlantillaSms) {
      loadEditTemplate()
      setShowAddFiles(true)
    }
    getImages()
    $('#panelColapse').click()
    setTimeout(function () {
      setSpiner(false)
    }, 2000)
  }, [])

  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }

  const getImages = async () => {
    const imageData = await templateServices.getImages({})
    if (imageData.error === 200) {
      if (imageData.data.data.totalRows > 0) {
        setImgData(imageData.data.data.row)
      }
    } else {
      mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
    }
  }

  const loadEditTemplate = async () => {
    try {
      console.log(4)
      const resp = await templateServices.loadTemplatesEdit()
      mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
      const idGrupo = 35
      const parametro = await servi.getParametrosPorId(idGrupo)
          console.log(parametro.data.data.row.valor)
          if (parametro.data.code === 200) {
            if (parametro.data.data.row.valor === 'N') {
              setValor(true)
              setNameRem('CNEL Informa')
            }
          }
      if (resp.error === 200) {
        setNameTemplate(resp.data.data.nombre)
        setDescTemplate(resp.data.data.descripcion)
        if (resp.dataDet.data.row.length > 0) {
          setNameRem(resp.dataDet.data.row[0].nombreRemitente)
          setDescRem(resp.dataDet.data.row[0].mailRemitente)
          setAsunto(resp.dataDet.data.row[0].asunto)
          unlayer.loadDesign(JSON.parse(resp.dataDet.data.row[0].contenidoJson))
        }
      }
    } catch (e) {
    }
  }

  const saveHead = () => {
    unlayer.saveDesign(function (design) {
      // Do something with the json and html
      unlayer.exportHtml(function (data) {
        const html = data.html
        saveHeadJson(html, design)
      })
    })
  }

  const saveHeadJson = async (html, design) => {
    if (showLoad) { setSpiner(true) }
    $('#nameTemplate').removeClass('error')
    $('#descTemplate').removeClass('error')
    $('#nameRemit').removeClass('error')
    $('#descRemit').removeClass('error')
    $('#asuRemit').removeClass('error')
    const userData = await templateServices.saveHeadTemplates({
      asunto,
      nameTemplate,
      descTemplate,
      nameRem,
      descRem,
      html,
      design
    })
    if (showLoad) { setSpiner(false) }
    if (userData.errorValidation === 0) {
      if (userData.error === 200) {
        setOpcion(1)
        setShowAddFiles(true)
      }
    } else {
      setOpcion(userData.validate.clickPanel)
      userData.validate.nameTemplate && $('#' + userData.validate.nameTemplate).addClass('error')
      userData.validate.descTemplate && $('#' + userData.validate.descTemplate).addClass('error')
      userData.validate.nameRemit && $('#' + userData.validate.nameRemit).addClass('error')
      userData.validate.descRemit && $('#' + userData.validate.descRemit).addClass('error')
      userData.validate.asuRemit && $('#' + userData.validate.asuRemit).addClass('error')
    }
    mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
  }

  const saveDesignDetail = () => {
    unlayer.saveDesign(function (design) {
      // Do something with the json and html
      unlayer.exportHtml(function (data) {
        const html = data.html
        saveDetail(html, design)
      })
    })
  }

  const saveDetail = async (html, design) => {
    if (showLoad) { setSpiner(true) }
    $('#nameTemplate').removeClass('error')
    $('#nameRemit').removeClass('error')
    $('#descRemit').removeClass('error')
    $('#asuRemit').removeClass('error')
    const userData = await templateServices.saveDetailJustTemplates({
      asunto,
      nameTemplate,
      descTemplate,
      nameRem,
      descRem,
      html,
      design
    })
    if (showLoad) { setSpiner(false) }
    if (userData.errorValidation === 0) {
      if (userData.error === 200) {
        setOpcion(1)
        setShowAddFiles(true)
      }
    } else {
      setOpcion(userData.validate.clickPanel)
      userData.validate.nameTemplate && $('#' + userData.validate.nameTemplate).addClass('error')
      userData.validate.nameRemit && $('#' + userData.validate.nameRemit).addClass('error')
      userData.validate.descRemit && $('#' + userData.validate.descRemit).addClass('error')
      userData.validate.asuRemit && $('#' + userData.validate.asuRemit).addClass('error')
    }
    mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
  }

  const automaticSave = async () => {
    try {
      setShowLoad(false)
      saveHead()
      saveDesign()
      setShowLoad(true)
    } catch (e) {
    }
  }

  const removeError = (opt) => {
    $('#' + opt.id).removeClass('error')
  }

  setInterval(automaticSave, 15000000)

  $(document).ready(function () {
    $('input[type=file]').change(function (e) {
      const fileName = $('#fileUpload').val()
      $('.fileName').html(fileName)
    })
  })

  const uploadImg = async () => {
    const fileName = $('#fileUpload').val()
    setHandle4(handleClose)
    setSpiner(true)
    const userData = await templateServices.saveImages({
      img: selectedFile,
      idPlanitlla: localStorage.getItem('idPlantilla'),
      fileName
    })
    if (userData.errorValidation === 0) {
      if (userData.error === 200) {
        setShowAddFiles(true)
        getImages()
      }
    }
    setSpiner(false)
    mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
  }

  const handleClose = () => () => {
    setIsOpen(false)
    setIsOpenM(false)
    setIsOpenHtml(false)
    setIsOpenC(false)
  }

  const handleOnchange = event => {
    setNewHtml(event.target.value)
    // const design = Unlayer2be.fromHtml(event.target.value)
    // unlayer.loadDesign(design)
    unlayer.exportHtml(function (data) {
      const json = data.design.body.values // design json
      const design = Unlayer2be.fromHtml(event.target.value)
      design.body.values = json
      const x = $.extend({}, design, json)
      unlayer.loadDesign(x)
    })
  }

  const exportHtmlCode = () => {
    unlayer.exportHtml(function (data) {
      const html = data.html // final html
      setIsOpen(true)
      setHeader('HTML')
      setModalBody(<textarea className='text-html' type="text" onChange={handleOnchange}>{html}</textarea>)
      setModalButton('Cerrar')
      setHandle(handleClose)
    })
  }

  const cancelSmsTemplates = () => {
    setIsOpenC(true)
    setColorHeaderC(global.MODAL_HEAD_CANCEL_TEMPLATE)
    setBodyC(global.MODAL_CANCEL_TEMPLATE)
    setModalButtonC(global.ACCEPT)
    setModalButtonCerrarC(global.CANCEL)
    setHandleC(handleClose)
    setType('H')
  }

  // código para guardar automaticamente cada vez qye se arrastre un elemento
  unlayer.addEventListener('design:updated', function (updates) {
    // Design is updated by the user

    unlayer.exportHtml(function (data) {
      const json = data.design // design json
      const html = data.html // design html
      // Save the json, or html here
    })
  })

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const addFiles = (e) => {
    window.localStorage.setItem('addFiles', global.ROUTE_EDITMAILTEMPLATES)
    window.location.href = global.ASDD_FILES
  }

  const message = () => {
    $('.' + global.ERROR_LABEL + '-alert').html('')
    $('.' + global.ERROR_LABEL + '-alert').append(global.LABEL_INPUT_ERROR)
    $('.' + global.ERROR_LABEL).removeClass(global.ERROR_LABEL + '-alert-hide')
    $('.' + global.ERROR_LABEL).addClass(global.ERROR_LABEL + '-alert-show')
    setTimeout(function () {
      $('.' + global.ERROR_LABEL + '-alert').html('')
      $('.' + global.ERROR_LABEL).removeClass(global.ERROR_LABEL + '-alert-show')
      $('.' + global.ERROR_LABEL).addClass(global.ERROR_LABEL + '-alert-hide')
    }, 5000)
  }

  const clear = () => {
    setNameTemplate('')
    setDescTemplate('')
    // setNameRem('')
    //  setDescRem('')
    setAsunto('')
    unlayer.loadDesign(template)
    mostrarMensaje('200', 'Se limpió el formulario')
  }

  const handleSegment = (opt) => {
    setOpcion(opt)
  }

  const deleteImg = async (data) => {
    console.log('idImagenUsuario', data.idImagenUsuario)
    console.log('data', data)
    let validUser = helpServices.getValidUser(data.idUsuario)
    console.log('validUser', validUser)
    if (validUser) {
      console.log('entra elim')
      setSpiner(true)
      const userData = await templateServices.deleteImage(data.idImagenUsuario)
      setSpiner(false)
      let objMessage = helpServices.getMessage(userData.data)
      console.log('objMessage', objMessage)
      if (userData.code === 200) {
        console.log('entroo OK')
        getImages()
        mostrarMensaje(objMessage.code, objMessage.message)
      } else {
        mostrarMensaje(objMessage.code, objMessage.message)
      }
    } else {
      mostrarMensaje(400, global.MESSAGE_IMAGE_DELETE)
    }
  }

  const getParametro = async () => {
    const idGrupo = 35
    const parametro = await service.getParametrosPorId(idGrupo)
    console.log(parametro.data.data.row.valor)
    if (parametro.data.code === 200) {
      if (parametro.data.data.row.valor === 'N') {
        setValor(true)
        setNameRem('CNEL Informa')
      } else {
        setValor(false)
        setNameRem('')
      }
    }
  }
  return (
    <div className='container-fluid-master'>
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
      <div id="mediaLibrary" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{global.MY_LIBRARY} </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">

                <div className="row col-md-12">
                  <div className="col-md-6">
                    <div className="upload">
                      <input type="button" className="uploadButton" value="Browse" />
                      <input type="file" name="upload" accept="image/*" onChange={changeHandler} id="fileUpload" />
                      <span className="fileName">{global.SELECT_A_IMG}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <a type="button" className="uploadButton" onClick={uploadImg} >{global.SAVE_IMG}</a>
                  </div>
                </div>
                <br />
                <div className="col-md-12">
                  <h4>{global.SELECT_IMG}</h4>
                </div>
                <div className="images col-md-12 row">
                  {imgData.map((val, key) => {
                    return (
                      <React.Fragment key={val.idImagenUsuario}>
                        {
                          <div className="col-md-3">
                            <div className="image">
                              <i onClick={() => deleteImg(val)} className="fa fa-circle-xmark delete-img" aria-hidden="true"></i>
                              <img className='img-templates' src={val.urlPublica} />
                            </div>
                          </div>
                        }
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCancelTemplate isOpen={isOpenC} type={type} header={coloHeaderC} body={bodyC} color={coloHeaderC} button={modalButtonC} buttonCerrar={modalButtonCerrarC} handle={handleC} />
      <ModalNewMessages isOpen={isOpenM} header={headerM} body={bodyM} color={coloHeaderM} button={modalButtonM} handle={handle4} />
      <ModalHtmlBasic isOpen={isOpenHtml} header={headerHtml} body={bodyHtml} color={coloHeaderHtml} button={modalButtonHtml} handle={handle2} />
      <ModalSpiner opt={spiner} />
      <ModalHtmlTemplate isOpen={isOpen} header={header} color={coloHeader} button={modalButton} handle={handle} body={body} />
      <form>
        <div className="row">
          <div className="col">
            <div className='col-md-7'><a href={global.ROUTE_TEMPLASTES}><h4><i className="fa fa-chevron-left" aria-hidden="true"></i>{global.RETURN_TEMPLATE}</h4></a></div>
          </div>
        </div>
      </form>
      <hr className='hr2' />
      <h3 className='center' id='panelColapse' data-toggle="collapse" data-target="#demo">{global.CREATE_MAIL_TEMPLATE} <i className='fa fa-arrow-down'></i></h3>
      <hr className='hr2' />
      <div id="demo" className="row collapse col-md-12">

        <div className='row col-12 align-items-center justify-content-center mb-2'>
          <div className='col-4 p-text-l'>
            <div type='button' onClick={() => handleSegment(1)}>{global.CONTENT}
              <div className={opcion === 2 ? 'd-none' : 'success-panel'} id='panel-sub'>
                <hr className='sub-color-template'></hr>
              </div>
            </div>
          </div>
          <div className='col-4'></div>
          <div className='col-4'>
            <div type='button' onClick={() => handleSegment(2)}>{global.TEMPLATE_CONFIG}
              <div className={opcion === 1 ? 'd-none' : 'success-panel'} id='panel-sub2'>
                <hr className='sub-color2-template'></hr>
              </div>
            </div>
          </div>
          {opcion === 1

            ? <div className='container-fluid'>
              <form className='container-fluid row'>
                <div className="form-group col-md-6">
                  <label className="form-label"><b>{global.TEMPLATE_NAME}</b><span className="text-input name-input"></span> </label><strong className="obl">{global.REQUIRED}</strong>
                  <input onKeyUp={({ target }) => removeError(target)} id="nameTemplate" placeholder={global.TEMPLATE_NAME} value={nameTemplate} type="text" onChange={({ target }) => setNameTemplate(target.value)} minLength="20" maxLength={global.TEMPLATE_NAME_LENGTH} className="form-control item" pattern='Ingrese Nombre' />
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label"><b>{global.TEMPLATE_DESCRIPTION}</b><span className="text-input name-input"></span> </label>
                  <input onKeyUp={({ target }) => removeError(target)} id="descTemplate" placeholder={global.TEMPLATE_DESCRIPTION} value={descTemplate} type="text" onChange={({ target }) => setDescTemplate(target.value)} minLength="20" maxLength={global.DESCRIPTION_LENGTH} className="form-control item" pattern='Ingrese Nombre' />
                </div>
              </form>
            </div>
            : <div className='container-fluid'>
              <form className='container-fluid row'>
                <div className=" col-md-12 row">
                  <div className="col-md-6">
                    <label className="form-label"><b>{global.TEWMPLATE_NAME_REMIT}</b><span className="text-input name-input"></span> </label><strong className="obl">{global.REQUIRED}</strong>
                    <input disabled={valor} onKeyUp={({ target }) => removeError(target)} id="nameRemit" placeholder={global.TEWMPLATE_NAME_REMIT} value={nameRem} type="text" onChange={({ target }) => setNameRem(target.value)} minLength="20" maxLength={global.NAME_REM_LENGTH} className="form-control item" pattern='Ingrese Nombre' />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label"><b>{global.TEMPLATE_NAME_REMIT_DESC}</b><span className="text-input name-input"></span> </label><strong className="obl">{global.REQUIRED}</strong>
                    <input disabled onKeyUp={({ target }) => removeError(target)} id="descRemit" placeholder={global.TEMPLATE_NAME_REMIT_DESC} value={descRem} type="text" onChange={({ target }) => setDescRem(target.value)} minLength="20" maxLength={global.MAIL_LENGTH} className="form-control item" pattern='Ingrese Nombre' />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label"><b>{global.TEMPLATE_ASUN}</b><span className="text-input name-input"></span> </label><strong className="obl">{global.REQUIRED}</strong>
                    <input onKeyUp={({ target }) => removeError(target)} id="asuRemit" placeholder={global.TEMPLATE_ASUN} value={asunto} type="text" onChange={({ target }) => setAsunto(target.value)} minLength="20" maxLength={global.SUBJECT_LENGTH} className="form-control item" pattern='Ingrese Nombre' />
                  </div>
                </div>
              </form>
            </div>
          }
        </div>
        <div className='row mb-1 col-md-12'>
          <div className="col-10 align-end-btn">
            <br />
            {' '} <a title={global.CODE_BTN} type="button" onClick={exportHtmlCode} className="btn btn-outline-dark"><i className='fa fa-code'></i></a>
            {' '} <a title={global.DELETE_BTN} type="button" onClick={clear} className="btn btn-outline-dark"><i className='fa fa-eraser'></i> </a>
            {' '} <button title={global.SAVE_BTN} type="button" onClick={saveDesignDetail} className="btn btn-outline-dark"><i className='fa fa-save'></i></button>
            {
              showAddFiles &&
              <a title={global.ATTACH_BTN} type="button" onClick={addFiles} className="btn btn-outline-dark"><i className='fa fa-paperclip'></i></a>
            }
            { /* {' '}<a type="button" onClick={exportHtml} className="btn btn-outline-dark"><i className='fa fa-eye'></i> previsualización</a>
            {' '}<a type="button" onClick={repoHtml} className="btn btn-outline-dark"><i className='fa fa-eye'></i> Repo Html</a> */}
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div id="editor-container"></div>
      </div>
      <br />
      <div className="container">
        <div className="row">
          <div className="text-center form-inline-div">
            <div className="form-group">
              <button onClick={cancelSmsTemplates} className='btnCancel' >{global.CANCEL}</button> {' '}{' '}{' '}
              <button onClick={saveHead} className='btnAcep' > {global.ACCEPT}</button>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
    </div>
  )
}

export default EditTemplate
