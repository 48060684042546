/* eslint-disable no-trailing-spaces */
/* eslint-disable no-empty */
/* eslint-disable space-before-blocks */
/* eslint-disable keyword-spacing */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable space-infix-ops */
/* eslint-disable semi */
/* eslint-disable indent */
/* eslint-disable eol-last */
/* eslint-disable no-lone-blocks */
/* eslint-disable block-spacing */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-use-before-define */
/* eslint-disable padded-blocks */
/* eslint-disable no-undef */
/* eslint-disable arrow-spacing */
/* eslint-disable no-useless-return */

import React, { useEffect, useState } from 'react'
import '../../scss/_sidebar.scss'
import DataSidebar from '../../static/menuSidebar.json'
import DataPath from '../../static/rutasPath.json'
import $, { data } from 'jquery'
import { Link } from 'react-router-dom'
import loginServices from '../../services/login'
import ModalSidebarPermisoDengado from '../util/ModalSidebarPermisoDengado'
import { red } from '@mui/material/colors'

const Sidebar = () => {
  const [sidebar, setSidebar] = useState([])
  const [panelCollapse2, setpanelCollapse2] = useState(false)
  
  const [showModalError, setShowModalError] = useState(false);
  const [currentRoute, setCurrentRoute] = useState('');
  const [redireccionamiento, setRedireccionamiento] = useState(false);
  const [index, setIndex]=useState()
  useEffect(() => {
    const data = DataSidebar
    setSidebar(data)
    setPathData();
    validarPermisos();
    console.log('sidebar', sidebar);
  }, [])
  const setPathData = (idpermiso) => {
   
      const path = window.location.pathname;
      DataPath.forEach((val) => {
        if (path === val.ruta) {
          setIndex(val.index);
          console.log('val', val);
        }
      });
      console.log('location', window.location.pathname);
  }
 const [permisoSidebar, setPermisoSidebar] = useState(false);
  // funcion para controlar errores de permisos
  const handleRedirectPermisos = async (val, isFirstExecution = true) => {
    console.log('val', val);
    console.log('permisosPagina', permisosPagina);

    let hasDifferentPermission = true;
  
    if (Array.isArray(val)) {
      hasDifferentPermission = !val.every((id) =>
        permisosPagina.some((permiso) => permiso.nombre === id)
      );
    } else {
      hasDifferentPermission =
        !permisosPagina.some(
          (permiso) =>
            permiso.nombre === val || permiso.nombre === val.nombrePermiso
        );
    }
    
    console.log('hasDifferentPermission', hasDifferentPermission);
    
    if (!hasDifferentPermission) {
      setShowModalError(true);
    } else {
      if (isFirstExecution) {
        console.log('val', val);
      }
    }
  };
  
  
  
  
  

   //  Funcion para restringir los permisos 
   const [permisosPagina, setPermisosPagina] = useState([]);

   const validarPermisos = async () => {
    try {
      const response = await loginServices.ValidarToken();
      const listPermisos = response.data.listPermisos;
  
      const updatedSidebar = DataSidebar.map(val => {
        const matchingPermiso = listPermisos.find(permiso => {
          if (Array.isArray(val.nombrePermiso) && Array.isArray(permiso.nombre)) {
            return permiso.nombre.some(id => id === val.nombrePermiso);
          } else if (Array.isArray(val.nombrePermiso)) {
            return val.nombrePermiso.some(id => id === permiso.nombre);
          } else if (Array.isArray(permiso.nombre)) {
            return permiso.nombre.some(id => id === val.nombrePermiso);
          } else {
            return permiso.nombre === val.nombrePermiso;
          }
        });
  
        if (matchingPermiso) {
          return { ...val, href: matchingPermiso.ruta };
        } else {
          return val;
        }
      });
  
      setSidebar(updatedSidebar);
    } catch (error) {
      console.error(error);
    }
  };
  
  
  
   
   const filteredSidebar = sidebar.filter((val) => {
    if (Array.isArray(val.idPermiso)) {
      return val.idPermiso.some((id) => permisosPagina.some((permiso) => permiso.idPermiso === id));
    } else {
      return permisosPagina.some((permiso) => permiso.idPermiso === val.idPermiso);
    }
  });

  const handleRedirect = (val) => {
      if (val === '#') {
        setShowModalError(true);
        return null; // Agrega una declaración de retorno explícita
      } else {
        console.log('no');
        return null; // Agrega una declaración de retorno explícita
      }
  };
  


 
  const closeModal= (op) => () => {
    $('#sidebarCollapse').click()
  }
  return (
    <div>
    <section className='menu-modal' id='modal-menu'>
      <div className='no-success-panel'id='newcollapseButton' onClick={closeModal(panelCollapse2)} >
      <i className="fa fa-bars collapseButton yellow-color"></i>
      </div>
      <div className='success-panel' id='lista1'>
        <nav id="sidebar" className="active menu-fix">
          <div className="navbar-collapse collapse show" id="navbarSupportedContent">
            <ul className="list-unstyled components mb-5">
              {
                sidebar.map((val, key) => {
                  return (
                    val.index === index
                      ? <React.Fragment key={val.id}>
                       <li className={val.id + 'Id selected active'}>
                       
                       <Link to={val.href}>
                          <div className={val.id + 'ClassBox selected square-box'}>
                            <div className={val.id + 'ClassBoxContent selected square-content'}>
                              <span className={val.classSpan + ' selected notify-color'}></span>
                            </div>
                          </div>
                          <div className="no-success-panel">
                            <div className="active-button" id="lista">
                              Listas
                            </div>
                          </div>
                          <div className={'optLabelInfo div-info-active ' + val.classInfo}>
                            <label className="label-info">{val.name}</label>
                          </div>
                        </Link>
                        
                      </li>
                      </React.Fragment>
                      : <React.Fragment key={val.id}>
                          <li onClick={()=> {setPathData(); handleRedirect(val.href)}} className={val.classLi}>
                            <Link to={val.href}>
                            <div className={val.classBox}>
                              <div className={val.classBoxContent}>
                                <span className={val.classSpan}></span>
                              </div>
                            </div>
                                                    
                            <div className={'optLabelInfo div-info-inactive ' + val.classInfo}>
                              <label className='label-info'>{val.name}</label>
                            </div>
                            </Link>                 
                          </li>
                        </React.Fragment>
                  )
                })
              }
            </ul>
          </div>
        </nav>
      </div>
      
    </section>
    {showModalError && (
      <ModalSidebarPermisoDengado
        open={showModalError}
        onClose={() => setShowModalError(false)}
      />
    )}

    </div>
  )
}
export default Sidebar
