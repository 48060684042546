/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multi-spaces */
/* eslint-disable indent */
/* eslint-disable multiline-ternary */
/* eslint-disable space-before-blocks */
/* eslint-disable quotes */
import React, { useState, useEffect } from 'react'
import '../../src/scss/_campaaign.scss'
import $ from 'jquery'
import ModalNewMessages from '../components/util/ModalCampaign'
import campaignServices from '../services/campaign'
import DataTable from 'datatables.net'
import ModalEditCampaign from '../components/util/ModalEditCampaign'
import ModalDeleteCampaign from '../components/util/ModalDeleteCampaign'
import Toast from '../components/util/Toast'
import ReactTooltip from 'react-tooltip'
import ModalSpiner from '../components/util/ModalSpiner'
import loginServices from '../services/login'
const Campaign = () => {
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [isOpen, setIsOpen] = useState(false)
  const [header, setHeader] = useState('')
  const [label1, setLabel1] = useState('')
  const [label2, setLabel2] = useState('')
  const [coloHeader, setColorHeader] = useState('')
  const [modalButton, setModalButton] = useState('')
  const [handle, setHandle] = useState()
  const [campaigns, setCampaigns] = useState('')
  const [data, setData] = useState([])
  const [isOpen2, setIsOpen2] = useState(false)
  const [header2, setHeader2] = useState('')
  const [label12, setLabel12] = useState('')
  const [label22, setLabel22] = useState('')
  const [coloHeader2, setColorHeader2] = useState('')
  const [modalButton2, setModalButton2] = useState('')
  const [handle2, setHandle2] = useState()
  const [valC, setValC] = useState({})
  const [isOpenDelete, setIsOpenDelete] = useState('')
  const [colorDeleter, setColorDeleter] = useState('')
  const [headerDeleter, setHeaderDeleter] = useState('')
  const [bodyDeleter, setBodyDeleter] = useState('')
  const [buttonDeleter, setButtonDeleter] = useState('')
  const [buttonCerrarDeleter, setButtonCerrarDeleter] = useState('')
  const [iddeleter, setIddeleter] = useState('')
  const [handleDeleter, setHandleDeleter] = useState('')
  const [spiner, setSpiner] = useState(false)
  const handleModal = (op) => () => {
    setIsOpen(true)
    setHeader('Crear nueva campaña')
    setLabel1('Nombre de la campaña')
    setLabel2('Objetivo de la campaña')
    setColorHeader()
    setModalButton('crear')
    setHandle(handleClosess)
  }
  const handleClosess = () => () => {
    $('#nameCamp2').val('')
    $('#textCamp2').val('')
    setIsOpen(false)
    // window.location.href = '/campaign'
  }
  const handleClose = () => () => {
    setIsOpen(false)
    setIsOpen2(false)
    setIsOpenDelete(false)
    // window.location.href = '/campaign'
  }
  const handleCloses = () => () => {
    setIsOpen(false)
    setIsOpen2(false)
  }

  useEffect(() => {
    try {
      const op = 'li5'
      $('.selected').removeClass('selected active square-box square-content notify-color')
      $('.' + op + 'id').addClass('selected active')
      $('.' + op + 'ClassBox').addClass('selected square-box')
      $('.' + op + 'ClassBoxContent').addClass('selected square-content')
      $('.' + op + 'ClassSpan').addClass('selected notify-color')
      window.localStorage.setItem(
        'menu', JSON.stringify(5)
      )
      $('#campaing-table').dataTable().fnDestroy()
      $('.form-control-sm').removeAttr('placeholder')

      setSpiner(true)
      validarPermisos()
      if (window.localStorage.getItem('campEli') === '1') {
        mostrarMensaje('' + window.localStorage.getItem('code'), '' + window.localStorage.getItem('message'))
        window.localStorage.removeItem('campEli')
      }
      if (window.localStorage.getItem('campEli') === '2') {
        mostrarMensaje('' + window.localStorage.getItem('code'), '' + window.localStorage.getItem('message'))
        window.localStorage.removeItem('campEli')
      }
      if (window.localStorage.getItem('campEli') === '3') {
        mostrarMensaje('' + window.localStorage.getItem('code'), '' + window.localStorage.getItem('message'))
        window.localStorage.removeItem('campEli')
      }
      campaignYable()
    } catch (e) {
    }
  }, [])
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }
  const campaignYable = async (event) => {
    try {
      const templates = await campaignServices.getCampaignTable({})
      if (templates.data.code === 200) {
        setSpiner(false)
        setCampaigns(templates.data.data.length)
        setData(templates.data.data)
        $('#campaing-table').find('tr').each(function () {
          $(this).css('text-align', 'center')
        })
        $('#campaing-table').DataTable({
          initComplete: function (settings, json) {
            $('body').find('.dataTables_scrollBody').addClass('scrollbar')
          },
          scrollY: '300px',
          scrollCollapse: true,
          order: [[2, 'desc']],
          lengthMenu: [5, 10, 20],
          language: {
            decimal: '',
            emptyTable: 'No hay información',
            info: 'Mostrando _START_ a _END_ de un total de _TOTAL_ Registros',
            infoEmpty: 'Mostrando 0 to 0 of 0 Registros',
            infoFiltered: '(Filtrado de _MAX_ total Registros)',
            infoPostFix: '',
            thousands: ',',
            lengthMenu: 'Mostrar _MENU_ Registros',
            loadingRecords: 'Cargando...',
            processing: 'Procesando...',
            search: 'Buscar:',
            zeroRecords: 'Sin resultados encontrados',
            paginate: {
              first: 'Primero',
              last: 'Ultimo',
              next: '>',
              previous: '<'
            }
          }
        })
        $('[data-toggle="tooltip"]').tooltip()
      } else {
        setSpiner(false)
        mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
      }
    } catch (e) {
    }
  }
  const deleterCamp = (id) => () => {
    setIsOpenDelete(true)
    setColorDeleter('notify-color ajust-modal')
    setHeaderDeleter('Eliminar campaña')
    setBodyDeleter('¿Está seguro de eliminar la campaña?')
    setButtonDeleter('Aceptar')
    setButtonCerrarDeleter('Cancelar')
    setIddeleter(id)
    setHandleDeleter(handleClose)
  }
  const editCamp = (campana) => () => {
    setIsOpen2(true)
    setHeader2('Actualizar campaña')
    setLabel12('Nombre de la campaña')
    setValC(campana)
    setLabel22('Objetivo de la campaña')
    setColorHeader2()
    setModalButton2('Actualizar')
    setHandle2(handleCloses)
  }

  //  Funcion para restringir los permisos 
  const [permisosPagina, setPermisosPagina] = useState([]);
  const [isValidCrearCampana, setIsValidCrearCampana] = useState(false);
  const [isValidConsultarCampana, setIsValidConsultarCampana] = useState(false);
  const validarPermisos = async () => {
    try {
      const response = await loginServices.ValidarToken();
      setPermisosPagina(response.data.listPermisos);
      const tienePermisoCrearCampana = response.data.listPermisos.some(
        (permiso) => permiso.nombre === "CREATE_CAMPAINS"
      );
      const tienePermisoConsultarCampana = response.data.listPermisos.some(
        (permiso) => permiso.nombre === "VIEW_CAMPAINS"
      );
      setIsValidCrearCampana(tienePermisoCrearCampana);
      setIsValidConsultarCampana(tienePermisoConsultarCampana);
      if (!tienePermisoConsultarCampana){
        window.location.href = global.ROUTE_DASHBOARD + '?param=true';
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
        <div className='container-fluid'>
        <ModalSpiner opt={spiner} />
        {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
         <ModalNewMessages isOpen={isOpen} header={header} label1={label1} label2={label2} color={coloHeader} button={modalButton} handle ={handle} />
        {isOpen2 && <ModalEditCampaign editCampaign={valC} isOpen={isOpen2} header={header2} label1={label12} label2={label22} color={coloHeader2} button={modalButton2} handle ={handle2} />}
        {isOpenDelete && <ModalDeleteCampaign isOpen={isOpenDelete} color={colorDeleter} header={headerDeleter} body={bodyDeleter} button={buttonDeleter} buttonCerrar={buttonCerrarDeleter} id={iddeleter} handle={handleDeleter}/>}
         <input type='hidden' id='actu'></input>
        {isValidCrearCampana && (
          <div className='col-12 create-campaign text-align-end'><a type='button' onClick={handleModal(1)} id='create' className="btn btn btn-success btn-campania btn-struct"><b><i className='fa fa-bullhorn bullhorn'></i> Crear campaña</b></a></div>
        )}
        {isValidConsultarCampana && (
          <div>
            <div className="mb-12 panel-group panel_campania mt-5">
              <div className='row mb-2'>
                <div className='col-5'>
                  <h4 className='campaing-tittle'><b>Campañas</b></h4>
                </div>
                <div className='col-7'>
                  <p className='right'><i className='fa fa-bullhorn'></i>  Total campañas  <b>{campaigns}</b></p>
                </div>
              </div>
            </div>

            <div className="mb-12 panel-group panel_campania table-responsive">
              <table id='campaing-table' className="table">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Objetivo</th>
                    <th>Fecha de creacion</th>
                    {isValidCrearCampana && (
                      <th>Opciones</th>
                    )}
                    
                  </tr>
                </thead>
                <tbody id='tbody'>
                  {data.map((val) => {
                    return (
                      <React.Fragment key={val.id}>
                        <tr key={val.id} className='odd'>
                          <td className='dataTables_empty'>
                            {val.campana.length > 30 ? (
                              <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.campana.toLowerCase()}</p>`} data-html={true}>
                                {val.campana.toLowerCase()}
                                <ReactTooltip place="bottom" type="dark" effect="solid"/>
                              </div>
                            ) : (
                              <>
                                <span>{val.campana.toLowerCase()}</span>
                              </>
                            )}
                          </td>
                          <td className='to' id={val.idCampana}>
                            {val.descripcion.length > 30 ? (
                              <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.descripcion.toLowerCase()}</p>`} data-html={true}>
                                {val.descripcion.toLowerCase()}
                                <ReactTooltip place="bottom" type="dark" effect="solid"/>
                              </div>
                            ) : (
                              <>
                                <span>{val.descripcion.toLowerCase()}</span>
                              </>
                            )}
                          </td>
                          <td>{val.fechaCrea}</td>
                          {isValidCrearCampana && (
                            <td>
                              <div className="dropdown text-center">
                                <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="fa fa-ellipsis-v fa-color"></i>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <div onClick={editCamp(val)} id='edit'><i className="ml-2 fas fa-pen notify-color fa-style"></i><i className='ml-3 font-syle'>Editar</i></div>
                                  <div onClick={deleterCamp(val.idCampana)} id='delete'><i className='ml-2 far fa-trash-alt notify-color'></i><i className='ml-3 font-syle'>Eliminar</i></div>
                                  <i></i>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        </div>
  )
}

export default Campaign
