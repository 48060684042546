/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable indent */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-multi-spaces */
/* eslint-disable semi */
import React, { useState, useEffect, PureComponent } from 'react'
import { Form } from 'react-bootstrap'
import '../../scss/_sticky_header.scss'
import '../../scss/_dashboard.scss'
import date from '../../services/global'
import DashboardCards from '../../static/dashboardCards.json'
import dashboardServices from '../../services/dashboard'
import campaignServices from '../../services/campaign'
import { LineChart, Line, PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import ModalSpiner from '../../components/util/ModalSpiner'
import '../../scss/_chart.scss'
import $ from 'jquery'
import ReactTooltip from 'react-tooltip'
import helpServices from '../../services/helper'
import Toast from '../../components/util/Toast'
import loginServices from '../../services/login'
import ModalSidebarPermisoDengado from '../../components/util/ModalSidebarPermisoDengado'

const d = date.getDate()

const Dashboard = () => {
  window.localStorage.setItem('menu', 1)
  const [dashboard, setDashboard] = useState([])
  const [cDesp, setCDesp] = useState(0)
  const [cProg, setCProg] = useState(0)
  const [nEnv, setNEnv] = useState(0)
  const [uFP, setUFP] = useState(0)
  const [desp, setDesp] = useState(0)
  const [pro, setPro] = useState(0)
  const [nE, setNe] = useState(0)
  const [uP, setUp] = useState(0)
  const [dataDetail, setDataDetail] = useState('')
  const [dataCab, setDataCab] = useState('')
  const [dataDetail2, setDataDetail2] = useState('')
  const [dataCab2, setDataCab2] = useState('')
  const [dataDetail3, setDataDetail3] = useState('')
  const [dataCab3, setDataCab3] = useState('')
  const [dataDetail4, setDataDetail4] = useState('')
  const [dataCab4, setDataCab4] = useState('')
  const [dataBars, setDataBars] = useState([])
  const [dataCampaign, setDataCampaign] = useState([])
  const [spiner, setSpiner] = useState(true)
  const [todos, setTodos] = useState(0)
  const [searchCampaign, setsearchCampaign] = useState('')
  // data temp
  const [tempCapaign, setTempCampaign] = useState('')
  const [tempCanal, setTempCanal] = useState('todos')
  const [tempCanalShow, setTempCanalShow] = useState('todos')
  const [fechaDesde, setFechaDesde] = useState('')
  const [fechaHasta, setFechaHasta] = useState('')
  const [dataPieCanal, setdataPieCanal] = useState([])
  const [dataMailing, setDataMailing] = useState([])
  const [dataSms, setDataSms] = useState([])
  const [dataWhats, setDataWhats] = useState([])
  const [initPage, setInitPage] = useState(0)
  const [typeSearch, setTypeSearch] = useState('T')
  const [labelMonth, setLabelMonth] = useState(1)
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [showModalError, setShowModalError] = useState(false);
  // const [COLORS, SetColors] = useState([])
  useEffect(() => {
    validarPermisos();
    setInitPage(0);
    setDashboard(DashboardCards);
    getDashboard('init', tempCapaign);
    $('#canal_todos').addClass('selected-canal');
    setTimeout(function () {
      setInitPage(1);
    }, 1000);
  
    // Obtener el valor del parámetro de la barra de consultas
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get('param');
  
    if (paramValue === 'true') {
      // Mostrar el modal
      setShowModalError(true);
    }
  
    return () => {
      // Aquí puedes realizar cualquier limpieza necesaria al desmontar el componente
    };
  }, []);
  

  const dataPieData = [
    { name: 'Mailing', value: 900 },
    { name: 'Group B', value: 50 },
    { name: 'Group C', value: 50 }
  ]

  const getDashboard = async (option, campaignData) => {
    setSpiner(true)
    let fechaInicioSend
    let fechaFinSend
    if (fechaDesde !== '') {
      fechaInicioSend = fechaDesde + ' 00:00:00'
    } else {
      fechaInicioSend = fechaDesde
    }
    if (fechaHasta !== '') {
      fechaFinSend = fechaHasta + ' 23:59:59'
    } else {
      fechaFinSend = fechaHasta
    }
    const dataDashboard = await dashboardServices.getDashboardData({ opt: option, fechaInit: fechaInicioSend, fechaFint: fechaFinSend, camp: campaignData, canal: tempCanal, typeSearch })
    setdataPieCanal(dataDashboard.ddDataCanal)
    setDataMailing(dataDashboard.ddDataMailing)
    setDataSms(dataDashboard.ddDataSms)
    setDataWhats(dataDashboard.ddDataWhats)
    setDesp(dataDashboard.notDesp)
    setPro(dataDashboard.notIng)
    setNe(dataDashboard.notSend)
    setUp(dataDashboard.dateDesp)
    setDataCab(dataDashboard.notDespDetailHead)
    setDataDetail(<div dangerouslySetInnerHTML={{ __html: dataDashboard.notDespDetailBody }}></div>)
    setDataCab2(dataDashboard.notProDetailHead)
    setDataDetail2(<div dangerouslySetInnerHTML={{ __html: dataDashboard.notProDetailBody }}></div>)
    setDataCab3(dataDashboard.notSendDetailHead)
    setDataDetail3(<div dangerouslySetInnerHTML={{ __html: dataDashboard.notSendDetailBody }}></div>)
    setDataCab4(dataDashboard.dateDespDetailHead)
    setDataDetail4(<div dangerouslySetInnerHTML={{ __html: dataDashboard.dateDespDetailBody }}></div>)
    // setamos en cero para que se presente primero la cabecera y no el detalle de la tarjeta
    setCDesp(0)
    setCProg(0)
    setNEnv(0)
    setUFP(0)
    setDataBars(dataDashboard.ddData)
    // consultamos la opcion de campañas
    const campaign = await campaignServices.getCampaignTable({})
    setDataCampaign(campaign.data.data)
    if (fechaDesde !== '' && fechaHasta !== '') {
      setLabelMonth(0)
    } else {
      setLabelMonth(1)
    }
    setSpiner(false)
  }

  const detailInfo = async (event) => {
    if (event === 'D') { setCDesp(1) }
    if (event === 'P') { setCProg(1) }
    if (event === 'E') { setNEnv(1) }
    if (event === 'U') { setUFP(1) }
  }

  const search = () => {
    let campaign = ''
    $('.ind-chk:checked').each(function () {
      if (campaign !== '') {
        campaign = campaign + ',' + $(this).val()
      } else {
        campaign = $(this).val()
      }
    })

    setTempCampaign(campaign)
    setTempCanalShow(tempCanal)
    getDashboard('search', campaign)
  }

  const setCanalAll = (opt) => {
    $('#canal_todos').removeClass('selected-canal')
    $('#canal_H').removeClass('selected-canal')
    $('#canal_T').removeClass('selected-canal')
    $('#canal_E').removeClass('selected-canal')
    $('#canal_todos').addClass('selected-canal')
  }

  const chkAll = (opt) => {
    $('#canal_todos').removeClass('selected-canal')
    $('#canal_H').removeClass('selected-canal')
    $('#canal_T').removeClass('selected-canal')
    $('#canal_E').removeClass('selected-canal')
    $('#canal_todos').addClass('selected-canal')
    if ($('.chk-todos').prop('checked')) {
      $('input[type=checkbox]:checked').each(function () {
        $('.ind-chk').prop('checked', true)
      })
      let campaign = ''
      $('.ind-chk:checked').each(function () {
        if (campaign !== '') {
          campaign = campaign + ',' + $(this).val()
        } else {
          campaign = $(this).val()
        }
      })
      setTempCampaign(campaign)
    } else {
      $('input[type=checkbox]:checked').each(function () {
        $('.ind-chk').prop('checked', false)
      })
    }
  }

  const Canal = (opt) => {
    setTempCanal(opt)
    setTypeSearch('C')
    $('#canal_todos').removeClass('selected-canal')
    $('#canal_H').removeClass('selected-canal')
    $('#canal_T').removeClass('selected-canal')
    $('#canal_E').removeClass('selected-canal')
    $('#canal_' + opt).addClass('selected-canal')
    $('input[type=checkbox]:checked').each(function () {
      $('.ind-chk').prop('checked', false)
    })
    $('.chk-todos').prop('checked', false)
  }

  const cabInfo = (event) => {
    if (event === 'D') {
      setCDesp(0)
    }
    if (event === 'P') {
      setCProg(0)
    }
    if (event === 'E') {
      setNEnv(0)
    }
    if (event === 'U') {
      setUFP(0)
    }
  }

  const COLORS = ['#D0185C', '#DD168B', '#A54094', '#442B89', '#5835B7', '#1A32B7', '#00BFD8', '#009988', '#ECE99B', '#EFA8B8', '#E9ACCA', '#BA97C6', '#39C8F2']
  const COLORSCANAL = ['#D0185C', '#DD168B', '#A54094']
  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="Hanging">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  class CustomizedLabel extends PureComponent {
    render () {
      const { x, y, stroke, value } = this.props
      return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={3} textAnchor="middle">
          {value}
        </text>
      )
    }
  }

  class CustomizedAxisTick extends PureComponent {
    render () {
      const { x, y, stroke, payload } = this.props

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-80)">
            {payload.value}
          </text>
        </g>
      )
    }
  }

  const onKeyUpSearch = async (event) => {
    if (event.keyCode === 13) {
      setSpiner(true)
      const campaign = await campaignServices.getCampaignSeach({
        search: searchCampaign
      })
      setDataCampaign(campaign.data.data)
    } else {
      if (event.keyCode === 8) {
        if (searchCampaign === '') {
          setSpiner(true)
          const campaign = await campaignServices.getCampaignTable({})
          setDataCampaign(campaign.data.data)
        }
      }
    }
    setSpiner(false)
  }
  const handleKeyPress = (event, valMax) => {
    let valid = helpServices.validaNombres(event)
    if (!valid) return event.preventDefault()
  }
  const handleOnchange = event => {
    const vali2 = helpServices.verificaSoloTextoNumero(event.target.value)
    if (vali2 === false) {
      mostrarMensaje('400', 'No se permite caracteres especiales')
    } else {
      setsearchCampaign(event.target.value)
    }
  }
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }

    const validarPermiso = () => {
      return new Promise((resolve) => {
        loginServices.ValidarToken()
          .then((response) => {
            setPermisosPagina(response.data.listPermisos);
            resolve(response.data.listPermisos);
          })
          .catch((error) => {
            // Manejar el error de la solicitud CrearEmpresa
            console.error(error);
            resolve(null); // O manejar el error de alguna otra manera
          });
      });
    };
    
    const recorrerPermisos = async (permiso) => {
      const permisos = await validarPermiso();
      console.log(permisos);
      const encontrado = permisos.map((item) => {
        if (item.nombre === 'nn') {
          console.log(item.nombre);
          // Se detiene la iteración
        }
        return item.nombre === 'nn';
      });
    
      if (!encontrado) {
        console.log(permisosPagina[0].ruta);
        window.location.href = `${permisosPagina[0].ruta}`;
      }
    };
     //  Funcion para restringir los permisos 
    const [permisosPagina, setPermisosPagina] = useState([]);
    const [isValidConsultarDashboard, setIsValidConsultarDashboard] = useState(false);
    const validarPermisos = async () => {
      try {
        const response = await loginServices.ValidarToken();
        console.log(response.data.listPermisos);
        setPermisosPagina(response.data.listPermisos);
        
        setIsValidConsultarDashboard(tienePermisoConsultarDashboard);
      } catch (error) {
                console.error(error)
      }
    };
  
  return (
    <>
      <ModalSpiner opt={spiner} />
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
      <nav className='nav-sticky'>
        <ul className='ul-sticky col-md-12 row'>
          <li className='li-sticky-dashboard row'>
            <div className='notify-color magin-left-menu '>
            { labelMonth === 1 && <b>{global.LAST_MONTH}</b> }
            </div>
          </li>
          <li className='li-sticky-dashboard row'>
            <div className='magin-left-menu '>
              <i className='fa fa-bullhorn class-icon' aria-hidden="true"></i> {global.FOR_CAMPAIGN}
              {' '}
              <i className="fa fa-chevron-down notify-color" aria-hidden="true" id="ddCampaignMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
              <div className="dropdown-menu dashboard-cmp show-style search-campania" aria-labelledby="ddCampaignMenu">
                <div className='content-dropdonw-show'>
                  <div className='col-md-12 row'>
                    <div className='col-md-4 search-campaign'>
                      {global.SEARCH_CAMPAIGN}:
                    </div>
                    <div className='col-md-8'>
                      <input onKeyUp={(e) => onKeyUpSearch(e)}
                             value={searchCampaign}
                             onChange={handleOnchange}
                             onKeyPress={(event) => { handleKeyPress(event, 45) }}
                             className='form-control input-find' type="text" />
                    </div>
                  </div>
                  <div className='col-md-12'> <label className='notify-color head-dd-camp'>{global.DASCHBOARD_CAMPAIGN}</label></div>
                  <div className="global">
                    <div className='col-md-12'>
                      <div id="mensajes" className="form-check">
                        <input onClick={({ target }) => setTypeSearch('CPG')} onChange={({ target }) => chkAll(target)} className="form-check-input chk-todos" type="checkbox" />
                        <label className="texto form-check-label">
                          {global.DASHBOARD_ALL}
                        </label>
                      </div>
                    </div>
                    <hr />
                    {
                      dataCampaign &&
                      dataCampaign.map((val, key) => {
                        return (
                          <React.Fragment key={val.id}>
                            {
                              <>
                                <div className='col-md-12'>
                                  <div id="mensajes" className="form-check">
                                    <input onClick={({ target }) => setTypeSearch('CPG')} onChange={({ target }) => setCanalAll()} className="form-check-input ind-chk" value={val.idCampana} type="checkbox" />
                                    <label className="texto form-check-label">
                                    {val.campana.length > 30
                                      ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.campana.toLowerCase()}</p>`} data-html={true}>
                                    {val.campana.toLowerCase()}
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                      : <>
                                  <span>{val.campana.toLowerCase()}</span>
                                  </>
                                }
                                    </label>
                                    <label className="texto form-check-label">
                                      {'-' + val.fechaCrea}
                                    </label>
                                  </div>
                                </div>
                                <hr />
                              </>
                            }
                          </React.Fragment>
                        )
                      })}
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </li>
          <li className='li-sticky-dashboard row'>
            <div className='magin-left-menu '>
              <i className='fa fa-calendar class-icon' aria-hidden="true"></i>  {global.BY_DATE}
              <b>
                {' '}
                <i className="fa fa-chevron-down notify-color" aria-hidden="true" id="ddDateMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                <div className="dropdown-menu dashboard-cmp show-style-fecha" aria-labelledby="ddDateMenu">
                  <div className="col-md-12 row">
                    <div className="col-md-6">
                      <Form.Group controlId="dob">
                        <Form.Label> {global.DATE_FROM}</Form.Label>
                        <Form.Control max={d} value={fechaDesde} onChange={({ target }) => setFechaDesde(target.value)} type="date" name="dob" placeholder="Date of Birth" />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="dob">
                        <Form.Label> {global.DATE_FROM_2}</Form.Label>
                        <Form.Control max={d} value={fechaHasta} onChange={({ target }) => setFechaHasta(target.value)} type="date" name="dob" placeholder="Date of Birth" />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </b>
            </div>
          </li>
          <li className='li-sticky-dashboard row'>
            <div className='magin-left-menu-search '>
              <i className='fa fa-calendar class-icon' aria-hidden="true"></i> Por canal
              <b>
                {' '}
                <i className="fa fa-chevron-down notify-color" aria-hidden="true" id="ddChannelMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                <div className="dropdown-menu dashboard show-style-canal" aria-labelledby="ddChannelMenu">
                  <a className="dropdown-item" id='canal_H' href="#" type="text" onClick={() => Canal('H')}>{global.TYPE_TEMPLATE_H}</a>
                  <a className="dropdown-item" href="#" type="text" id='canal_T' onClick={() => Canal('T')}>{global.TYPE_TEMPLATE_T}</a>
                  <a className="dropdown-item" href="#" type="text" id='canal_E' onClick={() => Canal('E')}>{global.TYPE_TEMPLATE_E}</a>
                  <a className="dropdown-item" href="#" type="text" id='canal_todos' onClick={() => Canal('todos')}>{global.DASHBOARD_ALL}</a>
                </div>
              </b>
            </div>
          </li>
          <li className='li-sticky-dashboard row'>
            <div className='magin-left-menu-search '>
              <a onClick={() => search()} type="button" className="btn btn btn-success btn_dash float-left-btn"><i className='fa fa-search'></i> {global.SEARCH_CAMPAIGN}</a>
            </div>
          </li>
          <li className='li-sticky-dashboard row'>
          </li>
          <li className='li-sticky-dashboard-indicator row'>
            <div className="col-md-12 panel-group panel_hist_template_no_border"></div>
            <div className="col-md-12 panel-group panel_hist_template">
              <div className="col-md-12">
                <div className="card-body">
                  <div className="home-content">
                    <div className="overview-boxes">
                      <div className="box first">
                        <div className='show-principal col-md-12 row top-indicator-dashboard'>
                          <div className='col-md-4 top-indicator'>
                            <i className="bx bx-cart-alt cart fa fa-bullhorn notify-color">
                            <label><i className="fa fa-check-circle fa-xl green-color" aria-hidden="true"></i></label></i>
                          </div>
                          <div className='col-md-4'>
                            <div className="right-side">
                              <div className="box-topic grey-color">{global.INDICADOR}</div>
                              <div className="number">{desp}</div>
                            </div>
                          </div>
                        </div>
                        <div className='show-second'>
                          <div className="form-group row ">
                            <div className="col-sm-12">
                              <b><label className="notify-color">{dataCab}</label></b><br />
                              {dataDetail}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="box second">
                        <div className='show-principal col-md-12 row top-indicator-dashboard'>
                          <div className='col-md-4 top-indicator'>
                            <i className="bx bxs-cart-add cart fa fa-bullhorn notify-color">
                            <label><i className="fa fa-clock fa-xl green-color" aria-hidden="true"></i></label>
                            </i>
                          </div>
                          <div className='col-md-4'>
                            <div className="right-side">
                              <div className="box-topic grey-color">{global.INDICADOR2}</div>
                              <div className="number">{pro}</div>
                            </div>
                          </div>
                        </div>
                        <div className='show-second'>
                          <div className="form-group row ">
                            <div className="col-sm-12">
                              <b><label className="notify-color">{dataCab2}</label></b><br />
                              {dataDetail2}
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="box third">
                        <div className='show-principal col-md-12 row top-indicator-dashboard-third'>
                          <div className='col-md-4 top-indicator'>
                            <i className="bx bx-cart cart fa fa-paper-plane notify-color"></i>
                          </div>
                          <div className='col-md-8'>
                            <div className="right-side" style={{ width: '150px' }}>
                              <div className="box-topic2 grey-color">{global.INDICADOR3}
                              <label className='notify-color' style={{ fontSize: '10px' }}><b>Mailing, SMS, Whatsapp</b></label></div>
                              <div className="number">{nE}</div>
                            </div>
                          </div>
                        </div>
                        <div className='show-second'>
                          <div className="form-group row">
                            <div className="col-sm-12">
                              <b><label className="notify-color">{dataCab3}</label></b><br />
                              {dataDetail3}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="box fourth">
                        <div className='show-principal col-md-12 row top-indicator-dashboard-third'>
                          <div className='col-md-4 top-indicator'>
                            <i className="bx bxs-cart-download cart fa fa-calendar notify-color"></i>
                          </div>
                          <div className='col-md-8'>
                            <div className="right-side" style={{ width: '150px' }}>
                              <div className="box-topic grey-color">{global.INDICADOR4}</div>
                              <div className="number">{uP}</div>
                            </div>
                          </div>
                        </div>
                        <div className='show-second'>
                          <div className="form-group row">
                            <div className="col-sm-12">
                              <b><label className="notify-color">{dataCab4}</label></b><br />
                              {dataDetail4}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <section className='section-sticky' id="first">
        {
          initPage === 1 &&
          <div className='global-body'>
            <div className='col-md-12 row'>
              <div className='col-md-12'>
                <div className='border border-default rounded'>
                  <h4 className="right-side tittle-char">Notificaciones enviadas</h4>
                  <hr /><br />
                  {
                    dataBars
                      ? dataBars.length > 0
                        ? <ResponsiveContainer width="100%" height={300}>
                          <LineChart
                            width={500}
                            height={300}
                            data={dataBars}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" height={90} tick={<CustomizedAxisTick />} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="cant" stroke="#8884d8" strokeWidth={5} label={<CustomizedLabel />} />
                          </LineChart>
                        </ResponsiveContainer>
                        : <div className='col-md-6 center'>No se ha encontrado información</div>
                      : <div className='col-md-6 center'>No se ha encontrado información</div>
                  }
                </div>
              </div>
              <div className='col-md-6 margin-top'>
                <div className='border border-default rounded'>
                  <h4 className="right-side tittle-char">{global.TITLE_DONUT1}</h4>
                  <hr /><br />
                  <div className='col-md-12 row margin-div-donut'>
                    {
                      dataPieCanal
                        ? dataPieCanal.length > 0
                          ? <>
                            <div className='col-md-6'>
                              <ResponsiveContainer width="100%" height={400}>
                                <PieChart width={700} height={700}>
                                  <Pie
                                    dataKey="value"
                                    data={dataPieCanal}
                                    cx="49%"
                                    cy="45%"
                                    labelLine={false}
                                    // label={renderCustomizedLabel}
                                    outerRadius={100}
                                    fill="#8884d8"
                                  >
                                    {dataPieCanal.map((entry, index) => (
                                      <Cell key={`cell-${index}`} fill={COLORSCANAL[index % COLORS.length]} />
                                    ))}
                                  </Pie>
                                  <Tooltip />
                                </PieChart>
                              </ResponsiveContainer>
                            </div>
                            <div className='col-md-6'>
                              <fieldset className="form-group">
                                <div className="row">
                                  <div className="col-sm-10 margin-label-donut">
                                    {dataPieCanal.map((entry, index) => (
                                      <>
                                        <div className="form-check">
                                          <div className={'colorDonut' + index}></div>
                                          <div className="form-group">
                                            <label className="form-check-label" htmlFor="gridRadios1">
                                              {entry.name}
                                            </label>
                                            <small id="emailHelp" className="form-text text-porcent">{entry.pv}%</small>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </>
                          : <div className='col-md-6 center'>No se ha encontrado información</div>
                        : <div className='col-md-6 center'>No se ha encontrado información</div>
                    }
                  </div>
                </div>
              </div>
              {
                tempCanalShow === 'todos'
                  ? <>
                    <div className='col-md-6 margin-top'>
                      <div className='border border-default rounded'>
                        <h4 className="right-side tittle-char">{global.TITLE_DONUT2}</h4>
                        <hr /><br />
                        <div className='col-md-12 row margin-div-donut'>
                          {
                            dataMailing
                              ? dataMailing.length > 0
                                ? <>
                                  <div className='col-md-8'>
                                    <ResponsiveContainer width="100%" height={400}>
                                      <PieChart width={700} height={700}>
                                        <Pie
                                          dataKey="value"
                                          data={dataMailing}
                                          cx="49%"
                                          cy="45%"
                                          labelLine={false}
                                          // label={renderCustomizedLabel}
                                          outerRadius={100}
                                          fill="#8884d8"
                                        >
                                          {dataMailing.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                          ))}
                                        </Pie>
                                        <Tooltip />
                                      </PieChart>
                                    </ResponsiveContainer>
                                  </div>
                                  <div className='col-md-4'>
                                    <fieldset className="form-group">
                                      <div className="row">
                                        <div className="col-sm-10 margin-label-donut global-donut">
                                          {dataMailing.map((entry, index) => (
                                            <>
                                              <div className="form-check">
                                                <div className={'colorDonut' + index}></div>
                                                <div className="form-group">
                                                  <label className="form-check-label" htmlFor="gridRadios1">
                                                    {entry.name}
                                                  </label>
                                                  <small id="emailHelp" className="form-text text-porcent">{entry.pv}%</small>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </>
                                : <div className='col-md-6 center'>No se ha encontrado información</div>
                              : <div className='col-md-6 center'>No se ha encontrado información</div>
                          }
                        </div>
                      </div>
                    </div><br />
                    <div className='col-md-6 margin-top'>
                      <div className='border border-default rounded'>
                        <h4 className="right-side tittle-char">{global.TITLE_DONUT3}</h4>
                        <hr /><br />
                        <div className='col-md-12 row margin-div-donut'>
                          {
                            dataSms
                              ? dataSms.length > 0
                                ? <>
                                  <div className='col-md-6'>
                                    <ResponsiveContainer width="100%" height={400}>
                                      <PieChart width={700} height={700}>
                                        <Pie
                                          dataKey="value"
                                          data={dataSms}
                                          cx="49%"
                                          cy="45%"
                                          labelLine={false}
                                          // label={renderCustomizedLabel}
                                          outerRadius={100}
                                          fill="#8884d8"
                                        >
                                          {dataSms.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                          ))}
                                        </Pie>
                                        <Tooltip />
                                      </PieChart>
                                    </ResponsiveContainer>
                                  </div>
                                  <div className='col-md-6'>
                                    <fieldset className="form-group">
                                      <div className="row">
                                        <div className="col-sm-10 margin-label-donut global-donut">
                                          {dataSms.map((entry, index) => (
                                            <>
                                              <div className="form-check">
                                                <div className={'colorDonut' + index}></div>
                                                <div className="form-group">
                                                  <label className="form-check-label" htmlFor="gridRadios1">
                                                    {entry.name}
                                                  </label>
                                                  <small id="emailHelp" className="form-text text-porcent">{entry.pv}%</small>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </>
                                : <div className='col-md-6 center'>No se ha encontrado información</div>
                              : <div className='col-md-6 center'>No se ha encontrado información</div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 margin-top'>
                      <div className='border border-default rounded'>
                        <h4 className="right-side tittle-char">Eficiencia WhatsApp</h4>
                        <hr /><br />
                        <div className='col-md-12 row margin-div-donut'>
                          {
                            dataWhats
                              ? dataWhats.length > 0
                                ? <>
                                  <div className='col-md-6'>
                                    <ResponsiveContainer width="100%" height={400}>
                                      <PieChart width={700} height={700}>
                                        <Pie
                                          dataKey="value"
                                          data={dataWhats}
                                          cx="49%"
                                          cy="45%"
                                          labelLine={false}
                                          // label={renderCustomizedLabel}
                                          outerRadius={100}
                                          fill="#8884d8"
                                        >
                                          {dataWhats.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                          ))}
                                        </Pie>
                                        <Tooltip />
                                      </PieChart>
                                    </ResponsiveContainer>
                                  </div>
                                  <div className='col-md-6'>
                                    <fieldset className="form-group">
                                      <div className="row">
                                        <div className="col-sm-10 margin-label-donut global-donut">
                                          {dataWhats.map((entry, index) => (
                                            <>
                                              <div className="form-check">
                                                <div className={'colorDonut' + index}></div>
                                                <div className="form-group">
                                                  <label className="form-check-label" htmlFor="gridRadios1">
                                                    {entry.name}
                                                  </label>
                                                  <small id="emailHelp" className="form-text text-porcent">{entry.pv}%</small>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </>
                                : <div className='col-md-6 center'>No se ha encontrado información</div>
                              : <div className='col-md-6 center'>No se ha encontrado información</div>
                          }
                        </div>
                      </div>
                    </div>
                  </>
                  : tempCanalShow === 'H'
                    ? <div className='col-md-6 margin-top'>
                      <div className='border border-default rounded'>
                        <h4 className="right-side tittle-char">Eficiencia Mailing</h4>
                        <hr /><br />
                        <div className='col-md-12 row margin-div-donut'>
                          {
                            dataMailing
                              ? dataMailing.length > 0
                                ? <>
                                  <div className='col-md-8'>
                                    <ResponsiveContainer width="100%" height={400}>
                                      <PieChart width={700} height={700}>
                                        <Pie
                                          dataKey="value"
                                          data={dataMailing}
                                          cx="49%"
                                          cy="45%"
                                          labelLine={false}
                                          // label={renderCustomizedLabel}
                                          outerRadius={100}
                                          fill="#8884d8"
                                        >
                                          {dataMailing.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                          ))}
                                        </Pie>
                                        <Tooltip />
                                      </PieChart>
                                    </ResponsiveContainer>
                                  </div>
                                  <div className='col-md-4'>
                                    <fieldset className="form-group">
                                      <div className="row">
                                        <div className="col-sm-10 margin-label-donut global-donut">
                                          {dataMailing.map((entry, index) => (
                                            <>
                                              <div className="form-check">
                                                <div className={'colorDonut' + index}></div>
                                                <div className="form-group">
                                                  <label className="form-check-label" htmlFor="gridRadios1">
                                                    {entry.name}
                                                  </label>
                                                  <small id="emailHelp" className="form-text text-porcent">{entry.pv}%</small>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </>
                                : <div className='col-md-6 center'>No se ha encontrado información</div>
                              : <div className='col-md-6 center'>No se ha encontrado información</div>
                          }
                        </div>
                      </div>
                    </div>
                    : tempCanalShow === 'T'
                      ? <div className='col-md-6 margin-top'>
                        <div className='border border-default rounded'>
                          <h4 className="right-side tittle-char">Eficiencia SMS</h4>
                          <hr /><br />
                          <div className='col-md-12 row margin-div-donut'>
                            {
                              dataSms
                                ? dataSms.length > 0
                                  ? <>
                                    <div className='col-md-6'>
                                      <ResponsiveContainer width="100%" height={400}>
                                        <PieChart width={700} height={700}>
                                          <Pie
                                            dataKey="value"
                                            data={dataSms}
                                            cx="49%"
                                            cy="45%"
                                            labelLine={false}
                                            // label={renderCustomizedLabel}
                                            outerRadius={100}
                                            fill="#8884d8"
                                          >
                                            {dataSms.map((entry, index) => (
                                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                          </Pie>
                                          <Tooltip />
                                        </PieChart>
                                      </ResponsiveContainer>
                                    </div>
                                    <div className='col-md-6'>
                                      <fieldset className="form-group">
                                        <div className="row">
                                          <div className="col-sm-10 margin-label-donut global-donut">
                                            {dataSms.map((entry, index) => (
                                              <>
                                                <div className="form-check">
                                                  <div className={'colorDonut' + index}></div>
                                                  <div className="form-group">
                                                    <label className="form-check-label" htmlFor="gridRadios1">
                                                      {entry.name}
                                                    </label>
                                                    <small id="emailHelp" className="form-text text-porcent">{entry.pv}%</small>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                          </div>
                                        </div>
                                      </fieldset>
                                    </div>
                                  </>
                                  : <div className='col-md-6 center'>No se ha encontrado información</div>
                                : <div className='col-md-6 center'>No se ha encontrado información</div>
                            }
                          </div>
                        </div>
                      </div>
                      : <div className='col-md-6 margin-top'>
                        <div className='border border-default rounded'>
                          <h4 className="right-side tittle-char">Eficiencia WhatsApp</h4>
                          <hr /><br />
                          <div className='col-md-12 row margin-div-donut'>
                            {
                              dataWhats
                                ? dataWhats.length > 0
                                  ? <>
                                    <div className='col-md-6'>
                                      <ResponsiveContainer width="100%" height={400}>
                                        <PieChart width={700} height={700}>
                                          <Pie
                                            dataKey="value"
                                            data={dataWhats}
                                            cx="49%"
                                            cy="45%"
                                            labelLine={false}
                                            // Gahlabel={renderCustomizedLabel}
                                            outerRadius={100}
                                            fill="#8884d8"
                                          >
                                            {dataWhats.map((entry, index) => (
                                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                          </Pie>
                                          <Tooltip />
                                        </PieChart>
                                      </ResponsiveContainer>
                                    </div>
                                    <div className='col-md-6'>
                                      <fieldset className="form-group">
                                        <div className="row">
                                          <div className="col-sm-10 margin-label-donut global-donut">
                                            {dataWhats.map((entry, index) => (
                                              <>
                                                <div className="form-check">
                                                  <div className={'colorDonut' + index}></div>
                                                  <div className="form-group">
                                                    <label className="form-check-label" htmlFor="gridRadios1">
                                                      {entry.name}
                                                    </label>
                                                    <small id="emailHelp" className="form-text text-porcent">{entry.pv}%</small>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                          </div>
                                        </div>
                                      </fieldset>
                                    </div>
                                  </>
                                  : <div className='col-md-6 center'>No se ha encontrado información</div>
                                : <div className='col-md-6 center'>No se ha encontrado información</div>
                            }
                          </div>
                        </div>
                      </div>
              }
            </div>
          </div>
        }
      </section>
      {showModalError && (
      <ModalSidebarPermisoDengado
        open={showModalError}
        onClose={() => setShowModalError(false)}
      />
    )}
    </>
  )
}

export default Dashboard
